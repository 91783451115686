'use client'
import React,{useState} from 'react';
import EmployAside from '../../aside';
import InformationsSociete from './informations_societe';

const MesDemarchesinfoSociete = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    

    <div className='main-page-wrapper'>
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>

      <InformationsSociete setIsOpenSidebar={setIsOpenSidebar}/>
    </div>    
  );
};

export default MesDemarchesinfoSociete;