import React, { useState } from "react";
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from "@mui/material";
import ReactQuill from "react-quill"; 
import "react-quill/dist/quill.snow.css"; 
import Swal from 'sweetalert2';
import { getAttachmentUploadP } from '../../../../api/filesService';
import axios from 'axios';
import { mutationSentEmail } from "../../../../api/emailService";

const ComposeEmailModal = ({ onClose, addressEmail }) => {

  const [emailData, setEmailData] = useState({
    sender: addressEmail, 
    destinationEmailAddresses: "", 
    subject: "",
    body: "",
  });
  const [useTemplate, setUseTemplate] = useState(false); 
  const [attachments, setAttachments] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBodyChange = (value) => {
    setEmailData((prev) => ({ ...prev, body: value }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments(files);
  };

  const uploadFiles = async () => {
    try {
      if (!selectedFiles || selectedFiles.length === 0) {
        console.log("Aucun fichier sélectionné.");
        return [];
      }
  
      const uploadedFileUrls = await Promise.all(
        selectedFiles.map(async (file) => {
          const response = await getAttachmentUploadP(file.name);
          const { url, fields } = response;
  
          const formData = new FormData();
          Object.entries(fields).forEach(([key, value]) => formData.append(key, value));
          formData.append("file", file); 
          console.log("file uploadFiles", `${url}/${fields.key}`);
          await axios.post(url, formData);
          return `${url}/${fields.key}`; 
        })
      );
  
      return uploadedFileUrls; 
    } catch (error) {
      console.error("Erreur lors du téléchargement des fichiers : ", error);
      return [];
    }
  };

  const handleSend = async () => {
    try {
      const uploadedFileUrls = await uploadFiles();
      console.log("Fichiers téléchargés avec succès :", uploadedFileUrls);
  
      const input = {
        sourceEmailAddress: emailData.sender,
        input: {
          body: emailData.body,
          subject: emailData.subject,
          destinationEmailAddresses: emailData.destinationEmailAddresses.split(','), 
          attachments: uploadedFileUrls || [],
        },
      };
      console.log("input", input);
      const response = await mutationSentEmail(input);
      console.log("Email envoyé :", response.data);
  
      Swal.fire({
        icon: "success",
        title: "Envoi",
        text: "Email envoyé avec succès.",
      });
  
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email :", error);
      alert("Erreur lors de l'envoi de l'email");
      Swal.fire({
        icon: "danger",
        title: "Envoi",
        text: "Email n'est pas envoyé avec succès.",
      });
    }
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <header className="modal-header" >
          <h3 style={{ color: "green" }}>Envoyer un Email</h3>
          <button className="close-modal" onClick={onClose}>×</button>
        </header>
        <form>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Expéditeur"
              value={emailData.sender}
              disabled
              InputProps={{
                style: { backgroundColor: "#f5f5f5" },
              }}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              label="Destinataire(s)"
              name="destinationEmailAddresses"
              value={emailData.destinationEmailAddresses}
              onChange={handleInputChange}
              helperText="Séparez les adresses par des virgules"
            />
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={useTemplate}
                onChange={(e) => setUseTemplate(e.target.checked)}
              />
            }
            label="Utiliser un modèle de mail"
          />

          <FormControl fullWidth margin="normal">
            <TextField
              label="Objet du mail"
              name="subject"
              value={emailData.subject}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl fullWidth margin="normal" sx={{ marginBottom: 3 }}>
          <label>Message</label>
            <ReactQuill
              value={emailData.body}
              onChange={handleBodyChange}
              theme="snow"
              style={{ height: '200px' }}
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["bold", "italic", "underline"],
                  ["link"],
                  [{ align: [] }],
                  ["image", "video"],
                  [{ 'size': ['small', 'medium', 'large', 'huge'] }]
                ],
              }}
            />
          </FormControl>
          <div className="quill-file-attachments">
            <label>Fichiers joints :</label>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              style={{ marginBottom: "10px" }}
            />
            {attachments.length > 0 && (
              <ul>
                {attachments.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            )}
          </div>

          {/* Boutons */}
          <div className="modal-footer">
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: "10px" }}
              onClick={handleSend}
            >
              Envoyer
            </Button>
            <Button variant="outlined" color="error" onClick={onClose}>
              Annuler
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ComposeEmailModal;
