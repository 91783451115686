import axiosInstance from "./axiosConfig";

const getPresignedUploadUrl = async (projectId, fileType, partnerId, fileName) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
        mutation generateCompanyOrderS3PreSignedUploadUrl($projectId: String!, $fileType: String!, $partnerId: String!, $fileName: String!) {
          generateCompanyOrderS3PreSignedUploadUrl(projectId: $projectId, fileType: $fileType, partnerId: $partnerId, fileName: $fileName) {
            url
            fields {
              key
              xAmzAlgorithm
              xAmzCredential
              xAmzDate
              xAmzSecurityToken
              policy
              xAmzSignature
            }
          }
        }
      `,
      variables: {
        projectId,
        fileType,
        partnerId,
        fileName,
      },
    });
    return response.data.data.generateCompanyOrderS3PreSignedUploadUrl;
  } catch (error) {
    console.error('Erreur lors de l\'obtention de l\'URL pré-signée:', error);
    throw error;
  }
};

export const getAttachmentUploadP = async (fileName) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
        mutation getAttachmentUploadPreSignedUrl($fileName: String!) {
        getAttachmentUploadPreSignedUrl(fileName: $fileName) {
                url
                fields {
                    key
                    xAmzAlgorithm
                    xAmzCredential
                    xAmzDate
                    xAmzSecurityToken
                    policy
                    xAmzSignature
                }
        }
      }
      `,
      variables: {
        fileName,
      },
    });
    return response.data.data.getAttachmentUploadPreSignedUrl;
  } catch (error) {
    console.error('Erreur lors de l\'obtention de l\'URL pré-signée:', error);
    throw error;
  }
};


