import axiosInstance from './axiosConfig';

export const fetchCheckoutUrl = async (projectName, serviceTypes) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
        mutation generateCheckoutUrlForNewProject($input: GenerateCheckoutUrlForNewProjectInput!) {
          generateCheckoutUrlForNewProject(input: $input) {
            url
          }
        }
      `,
      variables: {
        input: {
          projectName: projectName,
          serviceTypes: serviceTypes,
        },
      },
    });

    const checkoutUrl = response?.data?.data?.generateCheckoutUrlForNewProject?.url;

    if (checkoutUrl) {
      return checkoutUrl;
    } else {
      throw new Error('No checkout URL returned');
    }
  } catch (error) {
    console.error("Error fetching checkout URL:", error);
    throw error;
  }
};

