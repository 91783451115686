import { Link, useParams } from "react-router-dom";
import React, { useState } from "react";
import { TextField, Button, Typography, List, ListItem } from "@mui/material";
import  axiosInstance from '../../../api/axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { setDomainName } from '../../../redux/packageSlice';  

const CandidateV3Area = () => {
    const dispatch = useDispatch();
    const entireState = useSelector(state => state.package);  
     console.log("redux :", entireState);

    const {domainName} = useSelector(state => state.package ||"");
    const { projectId } = useParams(); 
    const [selectedDomain, setSelectedDomain] = useState(domainName || "");

    const [savedDomain, setSavedDomain] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");


    const handleDomainChange = (event) => {
        const domain = event.target.value;
        setSelectedDomain(domain);
        dispatch(setDomainName(domain));
      };
    const handleCheckDomain = async () => {
        if (domainName.trim()) {
            setLoading(true);
            setError("");
            setSavedDomain(""); 
    
            try {
                const response = await axiosInstance.post("/graphql", {
                    query: `
                        query checkDomainName($domainName: String!) {
                            checkDomainName(domainName: $domainName) {
                                availability
                                suggestions
                            }
                        }
                    `,
                    variables: { domainName: domainName },
                });
                const data = response.data.data.checkDomainName;
    
                if (data.availability) {
                    setSavedDomain(domainName);
                    setSuggestions([]);
                    dispatch(setDomainName(domainName));
                } else {
                    setSavedDomain(""); 
                    setSuggestions(data.suggestions);
                }
            } catch (err) {
                setError("Une erreur s'est produite lors de la vérification.");
                setSavedDomain(""); 
                setSuggestions([]);
            } finally {
                setLoading(false);
            }
        }
    };
    

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div
                        className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
                        style={{ paddingTop: "135px", paddingBottom: "30px" }}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h3 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                                    CRÉATION PACKAGE COMMERCIAL
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
                <div className="container">
                    <div className="text-center mb-4">
                        <div className="btn-group">
                            <span className="mx-3">1/15</span>
                        </div>
                    </div>
                    <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                        <h4 className="fw-600">
                            Choisissez un nom pour votre marque ou société pour le nom de domaine.
                            Nous vérifions si celui-ci est disponible, sinon nous vous proposerons des alternatives.
                        </h4>
                        <p className="fw-600">Nom de domaine : définition + exemple</p>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-6">
                                <div style={{ padding: "20px" }}>
                                    
                                    <TextField
                                        label="Nom de domaine"
                                        variant="outlined"
                                        fullWidth
                                        value={selectedDomain}
                                        onChange={handleDomainChange}
                                        className="mb-3"
                                    />
                                    <Button
                                        className="btn-eleven fw-500 tran3s d-block mt-20"
                                        variant="contained"
                                        fullWidth
                                        onClick={handleCheckDomain}
                                        disabled={loading}
                                    >
                                        {loading ? "Vérification..." : "Vérifier"}
                                    </Button>

                                    {error && (
                                        <Typography color="error" className="mt-3">
                                            {error}
                                        </Typography>
                                    )}

                                    {savedDomain && (
                                        <Typography color="success" className="mt-3">
                                            Le domaine "{savedDomain}" est disponible
                                        </Typography>
                                    )}

                                    {suggestions.length > 0 && (
                                        <>
                                            <Typography variant="h6" className="mt-4">
                                                Ce domaine n'est pas disponible. Voici des suggestions :
                                            </Typography>
                                            <List>
                                                {suggestions.map((suggestion, index) => (
                                                    <ListItem key={index}>{suggestion}</ListItem>
                                                ))}
                                            </List>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12">
                            <button
                                type="submit"
                                className="btn-eleven fw-500 tran3s d-block mt-20"
                                onClick={(e) => {
                                    if (!savedDomain) {
                                        e.preventDefault(); 
                                        setError("Veuillez vérifier un nom de domaine valide avant de continuer.");
                                    }
                                }}
                            >
                                <Link  to={savedDomain ? `/package-com2/${projectId}`: "#"} className="d-block">
                                    Continuer
                                </Link>
                            </button>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
};

export default CandidateV3Area;
