"use client";
import React from "react";
import { Link } from "react-router-dom"; // Use react-router-dom for routing
import { FaCheckCircle } from 'react-icons/fa'; // Example icons

const CandidateV3Area = () => {
  return (
    <>

<div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Choisir son statut</h2>
                                {/* <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <section className="blog-section-two pt-20 xl-pt-30 lg-pt-100 pb-150 xl-pb-130 lg-pb-80">
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to="/activite-statut-s3p1" className="btn btn-success">
                {"< Précédent"}
              </Link>
            </div>
            <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
              <h2 className="fw-600">EURL</h2>
            </div>
          </div>

          <div className="row mt-5 justify-content-center">
            <div className="col-md-8">
              <div className="info-card text-center">
                <div className="info-icon">
                  <FaCheckCircle />
                </div>
                <h3>Informations complémentaires</h3>
                <p>Icon</p>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12">
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/" className="d-block">
                Créer ma EURL
                </Link>
              </button>
            </div>
          </div>

          <br />
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <p className="fw-600">texte résumé du statut</p>
          </div>

          <div className="row mt-5">
            <div className="col-12">
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/" className="d-block">
                  En savoir plus
                </Link>
              </button>
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/" className="d-block">
                  Faire une autre simulation
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* CandidateFilterModal is omitted as it's not available */}
      <style jsx>{`
        .info-card {
          background: #fff;
          padding: 40px;
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          text-align: center;
        }
        .info-icon {
          font-size: 70px;
          color: #28a745;
          margin-bottom: 20px;
        }
        .info-card h3 {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .info-card p {
          font-size: 20px;
          font-weight: 500;
        }
      `}</style>
    </>
  );
};

export default CandidateV3Area;
