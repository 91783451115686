import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";


const CandidateV3Area = ({ style_2 }) => {
    const { projectId } = useParams(); 
    const [jobType, setJobType] = useState(style_2 ? "list" : "grid");

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop:'135px', paddingBottom:'30px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">              
                            <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION PACKAGE COMMERCIAL</h2>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
            <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
                <div className="container">
                    <div className="text-center mb-4">
                        <div className="btn-group">
                            <Link to={`/package-com2/${projectId}`}>
                                <button type="button" className="btn btn-success">
                                    {"< Précédent"}
                                </button>
                            </Link>
                            <span className="mx-3">3/15</span>
                        </div>
                    </div>
                    <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                        <h4 className="fw-600">Avez-vous un logo ?</h4>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to={`/package-com4Y/${projectId}`} className="d-block">Oui</Link>
                            </button>
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to={`/package-com4N/${projectId}`} className="d-block">Non, je souhaite en créer un</Link>
                            </button>

                            
                        </div>
                    </div>

                </div>
            </section>
          
        </>
    );
};

export default CandidateV3Area;
