import { createSlice } from '@reduxjs/toolkit';

const packageSlice = createSlice({
  name: 'package',
  initialState: {
    domainName: '',
    mailBoxEmailAddresses: [],
    address: '',  
    phone: '' ,
    hours: {
      first: ["", "","",""], 
      second: ["", "","",""], 
      third: ["", "","",""], 
      fourth: ["", "","",""], 
      fifth: ["", "","",""], 
      sixth: ["", "","",""], 
      seventh:["", "","",""],
    },
    socialNetworks: {
      youtube: '',
      linkedin: '',
      instagram: '',
      facebook: '',
      snapchat: '',
      twitter: ''
    },
    services: {
      text: '',
      items: []
    }
  },
  paragraph:'',
  fontFamily: '',
  backgroundColor: "",
  textColor: "",
  icon: "",
  s3ImageKey :"",
  colorForeground: "",
  colorBackground: "",
    title: "",
    slogan: "",
    fontTitle:"",
    fontSlogan:"",
   imgMagasin :"",
    gallery: [],
  
  reducers: {
    setDomainName: (state, action) => {
      state.domainName = action.payload;
    },
    setMailBoxEmailAddresses: (state, action) => {
      state.mailBoxEmailAddresses = action.payload; 
    },
    setAddress: (state, action) => {
      state.address = action.payload; 
    },
    setPhone: (state, action) => {
      state.phone = action.payload;  
    },
    setHours: (state, action) => {
      const { day, sessionIndex, value } = action.payload;
      const existingValue = state.hours[day];

      existingValue[sessionIndex] = value;

      if (existingValue[0] && existingValue[1] && existingValue[2] && existingValue[3]) {
        state.hours[day] = [
          `${existingValue[0]}`,`${existingValue[1]}`,  
          `${existingValue[2]}`,`${existingValue[3]}`,  
        ];
      }
    },


    setSocialNetworks: (state, action) => {
      const { socialMedia, link } = action.payload;
      state.socialNetworks[socialMedia] = link;
    },
    setServices: (state, action) => {
      state.services = action.payload;
    },
    setParagraph: (state, action) => {
      state.paragraph = action.payload;
    },
  
     setFontFamily: (state, action) => {
      state.fontFamily = action.payload;
    },
    setBackgroundColor: (state, action) => {
      state.backgroundColor = action.payload;
    },
    setTextColor: (state, action) => {
      state.textColor = action.payload;
    },
    setIcon:(state, action) => {
      state.icon = action.payload;
    },
    setColorForeground: (state, action) => {
      state.colorForeground = action.payload;
    },
    setColorBackground: (state, action) => {
      state.colorBackground = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
  },
  setSlogan: (state, action) => {
      state.slogan = action.payload;
  },
  setGallery:(state,action)=>{
    state.gallery = action.payload;
  },
  // addImage: (state, action) => {
  //   state.gallery.push(action.payload); 
  // },
  setimgLogo:(state,action)=>{
    state.s3ImageKey=action.payload;
  },
  setimgMagasin:(state,action)=>{
    state.imgMagasin=action.payload;
  },
  setFontTitle:(state,action)=>{
    state.fontTitle=action.payload;
  },
  setFontSlogan:(state,action)=>{
    state.fontSlogan=action.payload;
  },

  setProject: (state, action) => {
    const data = action.payload;
    state.domainName = data.domainName || state.domainName;
    state.mailBoxEmailAddresses = data.mailBoxEmailAddresses || state.mailBoxEmailAddresses;
    state.address =data.website?.globalDetails?.address || state.address;
    console.log("datecdc",state.domainName);

    state.phone = data.website?.globalDetails?.phoneNumber || state.phoneNumber;
    state.hours = data.website?.globalDetails?.days || state.hours;
    state.socialNetworks = data.website?.globalDetails?.socialNetworks || state.socialNetworks;
    state.services = data.services || state.services;
    state.paragraph = data.website?.about?.paragraph || state.paragraph;
    state.fontFamily = data.website?.globalDetails?.fontFamily || state.fontFamily;
    state.backgroundColor = data.website?.globalDetails?.backgroundColor || state.backgroundColor;
    state.textColor = data.website?.globalDetails?.textColor || state.textColor;
    state.icon = data.icon || state.icon;
    state.colorForeground = data.logo?.logoToCreate?.colorForeground || state.colorForeground;
    state.colorBackground = data.logo?.logoToCreate?.colorBackground || state.colorBackground;
    state.title = data.logo?.logoToCreate?.title || state.title;
    state.slogan = data.logo?.logoToCreate?.slogan || state.slogan;
    state.fontTitle = data.logo?.logoToCreate?.fontTitle || state.fontTitle;
    state.fontSlogan = data.logo?.logoToCreate?.fontSlogan || state.fontSlogan;
    state.gallery = data.website?.gallery;
    state.s3ImageKey = data.logo?.logoToCreate?.s3ImageKey || state.s3ImageKey;
    state.imgMagasin = data.website?.about?.s3Key || state.imgMagasin;

  },
  
  
  }
});

export const { 
  setDomainName, 
  setMailBoxEmailAddresses, 
  setAddress, 
  setPhone,
  setHours,
  setSocialNetworks,
  setServices,
  setParagraph,
  setFontFamily ,
  setBackgroundColor,
  setTextColor ,
  setIcon,
  setColorForeground,
  setColorBackground ,
  setTitle,
  setSlogan,
  setGallery,
  // addImage,
  setimgLogo,
  setimgMagasin,
  setFontSlogan,
  setFontTitle,
  setProject

} = packageSlice.actions;

export default packageSlice.reducer;
