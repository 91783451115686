import React from 'react';
import Header from "../Header";
import Footer from '../Footer';
import ScrollToTopButton from '../../Components/Theme/Theme';
import CreeatSociete from '../../Components/frontend/package-com/package-com9-area';



function PackageCom9() {
    return (
        <>
            <Header></Header>
            <CreeatSociete></CreeatSociete>
            <Footer></Footer>                      
            <ScrollToTopButton></ScrollToTopButton>                      
        </>
    );
}

export default PackageCom9;
