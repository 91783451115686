import axiosInstance from './axiosConfig';

export const fetchCreateCompany = async (input) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
         mutation upsertCompanyLegalStatusOnlyOrder($input: CompanyLegalStatusOnlyOrderInput) {
            upsertCompanyLegalStatusOnlyOrder(input: $input) 
        }
        `
      ,
      variables: {
        input,
      }

    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error occurred during company creation:", error);
    throw error;
  }
};

export const fetchGetCompany = async () => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
       query getCompanyOrders {
       getCompanyOrders{
        items {
            projectId
            projectName
            legalStatus
            projectStatus
            filesUploadedByAccountancyFirm{
                fileType
                s3Key
            }
            companyDetail {
                name
                address
                phone
                email
                meetingBookingUrl
                socialCapitalAmount
                socialCapitalCurrency
                capitalStockAmount
                capitalStockCurrency
            }
            companyPartners{
                partnerId
                name
                isMainLeader
                firstNames
                dateOfBirthday
                placeOfBirthday
                nationality
                professionalSituation
                socialSecurityNumber
                spouseIdentity{
                    name
                    maidenName
                    firstNames
                    dateOfBirthday
                    placeOfBirthday
                    nationality
                }
                files {
                    fileType
                    s3Key
                    validationStatus
                    validationMessage
                }
            }
        }
        nextToken
    }
}
      `
    });

    console.log('Response:', response.data);
    return response.data;

  } catch (error) {
    console.error("Error occurred during company get:", error);
    throw error;
  }
};



export const fetchGetCompanyProject = async (projectId) => {
  try {
    const response = await axiosInstance.post(
      "/graphql",
      {
        query: `
          query getCompanyOrder($projectId: String!) {
            getCompanyOrder(projectId: $projectId) {
              projectId
              projectName
              legalStatus
              projectStatus
              filesUploadedByAccountancyFirm {
                fileType
                s3Key
              }
              companyDetail {
                name
                address
                phone
                email
                meetingBookingUrl
                socialCapitalAmount
                socialCapitalCurrency
                capitalStockAmount
                capitalStockCurrency
              }
              companyPartners {
                partnerId
                name
                isMainLeader
                firstNames
                dateOfBirthday
                placeOfBirthday
                nationality
                professionalSituation
                socialSecurityNumber
                spouseIdentity {
                  name
                  maidenName
                  firstNames
                  dateOfBirthday
                  placeOfBirthday
                  nationality
                }
                files {
                  fileType
                  s3Key
                  validationStatus
                  validationMessage
                }
              }
            }
          }
        `,
        variables: { projectId },
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Include any authentication headers if needed
        },
      }
    );

    console.log("Response:", response.data);
    return response.data?.data?.getCompanyOrder;
  } catch (error) {
    console.error("Error occurred during company get:", error);
    throw error;
  }
};



export const fetchUpSertCompany = async (input) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `mutation upsertCompanyOrder($input: CompanyOrderInput) {
          upsertCompanyOrder(input: $input) 
      }
      `,
      variables: { input },
    });

    console.log('Response:', response.data);

    if (response.data.errors) {
      console.error("API Errors:", response.data.errors);
      throw new Error(response.data.errors.map(err => err.message).join(', '));
    }

    return response.data.data.upsertCompanyOrder;
  } catch (error) {
    console.error("Error occurred during company update:", error);
    throw error;
  }
};

