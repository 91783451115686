"use client";
import React from "react";
import Footer from "../../../layouts/Footer";
import ScrollToTopButton from "../../Theme/Theme";
import Header from "../../../layouts/Header";

const SimulatorInfo = () => {
  return (
    <>
        <Header/>

      <div className="hero-banner-two position-relative background_section">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Titre</h2>
                <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section-two pt-30 xl-pt-30 lg-pt-30 pb-20">
        <div className="container">
          <div className="position-relative">
            <div className="row gx-xxl-5 justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div style={{ width: '100%', height: '400px', position: 'relative', backgroundColor: '#ccc' }}>
                  <img 
                    src="https://via.placeholder.com/600x400?text=Simulateur+URSSAF" 
                    alt="Simulateur URSSAF" 
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 1
                    }}
                  />
                  
                  <iframe 
                    src=""
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 2
                    }}
                    frameBorder="0"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default SimulatorInfo;
