import React from "react";
import "../css/how-it-works.css";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from '@mui/lab';
import { Paper, Stack, Typography } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BalanceIcon from '@mui/icons-material/Balance';
import TaskIcon from '@mui/icons-material/Task';
import { timelineItemClasses } from '@mui/lab/TimelineItem';


const how_works_data= [
    {
      id: 1,
      icon: "/assets/images/icon/icon_08.svg",
      icon_white: "/assets/images/icon/icon_43.svg",
      title: "Create Account",
      sub_title: "Vous constituez votre dossier avec l'aide de nos équipes.",
    },
    {
      id: 2,
      icon: "/assets/images/icon/icon_09.svg",
      icon_white: "/assets/images/icon/icon_45.svg",
      title: "Complete your profile",
      sub_title:
        "Nos juristes répondent à vos questions. Vos statuts sont générés et vérifiés par nos équipes.",
    },
    {
      id: 3,
      icon: "/assets/images/icon/icon_10.svg",
      icon_white: "/assets/images/icon/icon_46.svg",
      title: "Apply job or hire",
      sub_title:
        "Vous déposez votre capital rapidement et simplement grâce à un de nos partenaires bancaires.",
    },
    {
      id: 4,
      icon: "/assets/images/icon/icon_10.svg",
      icon_white: "/assets/images/icon/icon_46.svg",
      title: "Apply job or hire",
      sub_title:
        "Votre entreprise est en cours d'immatriculation au greffe.",
    },
    {
      id: 5,
      icon: "/assets/images/icon/icon_10.svg",
      icon_white: "/assets/images/icon/icon_46.svg",
      title: "Apply job or hire",
      sub_title:
        "Vous recevez votre Kbis !",
    },
  ];

  function PositionedTimeline() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  
    return (
      <>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: isSmallScreen
              ? {
                  flex: 0,
                  padding: 0,
                }
              : undefined,
          }}
          position={isSmallScreen ? undefined : "alternate-reverse"}
        >
          <TimelineItem sx={{ alignItems: "center" }}>
            <TimelineSeparator>
              <TimelineConnector
                sx={{ height: "50px", background: "transparent" }}
              />
              <TimelineDot
                sx={{
                  backgroundColor: "rgb(162, 221, 199)",
                  borderColor: "white",
                  borderWidth: 5,
                  borderStyle: "solid",
                  width: 45,
                  height: 45,
                  margin: 0,
                }}
              />
              <TimelineConnector sx={{ height: "50px" }} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 1 }}>
                <Stack spacing={2} direction="row" alignItems={"center"}>
                  <ReceiptLongIcon
                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                  />
                  <Typography align="left" style={{ fontSize:'font-size: 1rem !important' }}>
                    Vous constituez votre dossier avec l’aide de nos équipes.
                  </Typography>
                </Stack>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={{ alignItems: "center" }}>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: "50px" }} />
              <TimelineDot
                sx={{
                  backgroundColor: "rgb(162, 221, 199)",
                  borderColor: "white",
                  borderWidth: 5,
                  borderStyle: "solid",
                  width: 45,
                  height: 45,
                  margin: 0,
                }}
              />
              <TimelineConnector sx={{ height: "50px" }} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 1 }}>
                <Stack spacing={2} direction="row" alignItems={"center"}>
                  <RecordVoiceOverIcon
                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                  />
                  <Typography align="left">
                    Nos juristes répondent à vos questions. Vos statuts sont
                    générés et vérifiés par nos équipes.
                  </Typography>
                </Stack>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={{ alignItems: "center" }}>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: "50px" }} />
              <TimelineDot
                sx={{
                  backgroundColor: "rgb(162, 221, 199)",
                  borderColor: "white",
                  borderWidth: 5,
                  borderStyle: "solid",
                  width: 45,
                  height: 45,
                  margin: 0,
                }}
              />
              <TimelineConnector sx={{ height: "50px" }} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 1 }}>
                <Stack spacing={2} direction="row" alignItems={"center"}>
                  <AccountBalanceIcon
                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                  />
                  <Typography align="left">
                    Vous déposez votre capital rapidement et simplement grâce à un
                    de nos partenaires bancaires.
                  </Typography>
                </Stack>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={{ alignItems: "center" }}>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: "50px" }} />
              <TimelineDot
                sx={{
                  backgroundColor: "rgb(162, 221, 199)",
                  borderColor: "white",
                  borderWidth: 5,
                  borderStyle: "solid",
                  width: 45,
                  height: 45,
                  margin: 0,
                }}
              />
              <TimelineConnector sx={{ height: "50px" }} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 1 }}>
                <Stack spacing={2} direction="row" alignItems={"center"}>
                  <BalanceIcon
                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                  />
                  <Typography align="left">
                    Votre entreprise est en cours d'immatriculation au greffe.
                  </Typography>
                </Stack>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={{ alignItems: "center" }}>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: "50px" }} />
              <TimelineDot
                sx={{
                  backgroundColor: "rgb(162, 221, 199)",
                  borderColor: "white",
                  borderWidth: 5,
                  borderStyle: "solid",
                  width: 45,
                  height: 45,
                  margin: 0,
                }}
              />
              <TimelineConnector
                sx={{ height: "50px", background: "transparent" }}
              />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 1 }}>
                <Stack spacing={2} direction="row" alignItems={"center"}>
                  <TaskIcon
                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                  />
                  <Typography align="left">Vous recevez votre Kbis !</Typography>
                </Stack>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </>
    );
  }
  
const HowItWorksTwo = () => {
  return (
    <section className="how-it-works-two position-relative" style={{ paddingTop: '40px' }}>
      <div className="container">
        <div className="title-one d-flex align-items-center justify-content-between text-center">
          {/* <span className="line"></span> */}
          <h2 className="fw-600 ps-3 pe-3 wow fadeInUp mx-auto" data-wow-delay="0.3s">Comment ça marche !</h2>
          {/* <span className="line"></span> */}
        </div>
        <PositionedTimeline />
        
        {/* <div className="stepper d-flex flex-column mt-5 ml-2">
          <div className="d-flex mb-1">
            <div className="d-flex flex-column pr-4 align-items-center">
              <div className="rounded-circle py-2 px-3 bg-primary text-white mb-1">1</div>
              <div className="line h-100"></div>
            </div>
            <div>
              <h5 className="text-dark">Create your application respository</h5>
              <p className="lead text-muted pb-3">Choose your website name & create repository</p>
            </div>
          </div>
          <div className="d-flex mb-1">
            <div className="d-flex flex-column pr-4 align-items-center">
              <div className="rounded-circle py-2 px-3 bg-primary text-white mb-1">2</div>
              <div className="line h-100"></div>
            </div>
            <div>
              <h5 className="text-dark">Clone application respository</h5>
              <p className="lead text-muted pb-3">Go to your dashboard and clone Git respository from the url in the dashboard of your application</p>
            </div>
          </div>
          <div className="d-flex mb-1">
            <div className="d-flex flex-column pr-4 align-items-center">
              <div className="rounded-circle py-2 px-3 bg-primary text-white mb-1">3</div>
              <div className="line h-100 d-none"></div>
            </div>
            <div>
              <h5 className="text-dark">Make changes and push!</h5>
              <p className="lead text-muted pb-3">Now make changes to your application source code, test it then commit &amp; push</p>
            </div>
          </div>
        </div> */}

        {/* <div className="border-bottom border-md0">
          <div className="row justify-content-center">
            {how_works_data.map((item) => (
              <div key={item.id} className="col-lg-4 col-md-6">
                <div className="card-style-five text-center position-relative mt-25 pb-35 lg-pb-20 wow fadeInUp">
                  <div className="numb fw-500 d-flex align-items-center justify-content-center m-auto">
                    <span>0{item.id}</span>
                  </div>
                  <div className="title fw-500 text-lg text-dark mt-25 mb-10">                    
                  </div>
                  <p>{item.sub_title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>  */}
               
      </div>
    </section>   
  );
};

export default HowItWorksTwo;
