import React, { useState } from "react";
import { Link } from "react-router-dom"; 

const CandidateV3Area = ({ style_2 }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [textareaValue, setTextareaValue] = useState("");

  const handleCheckboxChange = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
    );
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  return (
    <>
      <section className="candidates-profile bg-color pt-90 lg-pt-70 pb-160 xl-pb-150 lg-pb-80">
        <div className="container">
          <div className="text-center mb-4"></div>
          <div className="row mt-5">
            {/* Recapitulatif Card */}
            <div className="col-12 mb-4">
              <div className="card">
                <div className="card-header bg-success text-white text-center">
                  <h5 className="mb-0" style={{ color: 'white' }}>Paiement réussi</h5>
                </div>
                <div className="card-body d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                  <span style={{ fontSize: '1.2rem' }}>Votre paiement a bien été effectué. Retrouvez votre achat sur votre compte Pharos pour compléter et valider votre dossier.</span>
                </div>
              </div>
            </div>

            <div className="col-12 text-center mt-4">
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/dashboard">Retour à la page d’accueil</Link>
              </button>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default CandidateV3Area;
