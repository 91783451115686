import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchDownloafile } from "../../../api/webPackage";
import { useSelector } from "react-redux";
import '@fortawesome/fontawesome-free/css/all.min.css';


const CandidateV3Area = () => {
    const { projectId } = useParams();
    const {
        s3ImageKey,
        title,
        slogan,
        fontTitle,
        fontSlogan,
        colorForeground,
        colorBackground,
        icon,
    } = useSelector((state) => state.package);
console.log("icon",icon);
    const [logoUrl, setLogoUrl] = useState("");

    useEffect(() => {
        const getLogoUrl = async () => {
            if (s3ImageKey) {
                try {
                    const response = await fetchDownloafile(s3ImageKey);
                    setLogoUrl(response.data.generateWebpackageOrderS3PreSignedDownloadUrl.url);
                } catch (error) {
                    console.error("Erreur lors de la récupération de l'URL pré-signée :", error);
                }
            }
        };

        getLogoUrl();
    }, [s3ImageKey]);

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div
                        className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
                        style={{ paddingTop: "135px", paddingBottom: "30px" }}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                                    CRÉATION PACKAGE COMMERCIAL
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
                <div className="container">
                    <div className="text-center mb-4">
                        <div className="btn-group">
                            <Link to={`/package-com4Y/${projectId}`}>
                                <button type="button" className="btn btn-success">{"< Précédent"}</button>
                            </Link>
                            <span className="mx-3">6/15</span>
                        </div>
                    </div>
                    <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                        <h4 className="fw-600">Validez votre logo</h4>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 mb-4">
                            <div className="card p-4 border rounded shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">LOGO Visualisation</h5>
                                    <p className="card-text">
                                        Voici un aperçu de votre logo. Vérifiez les détails et confirmez s'il vous plaît.
                                    </p>
                                    <div
                                        className="logo-preview mt-3 d-flex justify-content-center align-items-center"
                                        style={{ minHeight: "200px" }}
                                    >
                                        {logoUrl ? (
                                            <img
                                                src={logoUrl}
                                                alt="Logo Preview"
                                                className="img-fluid"
                                                style={{ maxWidth: "300px", maxHeight: "200px" }}
                                            />
                                        ) : (
                                            <div
                                                className="logo-container"
                                                style={{ color: colorForeground, textAlign: "center" }}
                                            >
                                                <i className={`fa ${icon}`} style={{ fontSize: "100px", marginBottom: "20px" }}></i>
                                                <h1 style={{ fontFamily: fontTitle }}>{title}</h1>
                                                <p style={{ fontFamily: fontSlogan }}>{slogan}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to={`/package-com6/${projectId}`} className="d-block">
                                    Validez
                                </Link>
                            </button>
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to={`/package-com4N1V1/${projectId}`} className="d-block">
                                    Recommencez
                                </Link>
                            </button>
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to={`/package-com4N1V1/${projectId}`} className="d-block">
                                    Pas convaincu ? On vous met à disposition un designer ?
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
                <style>
                    {`
                       

                        .logo-container {
                            display: table;
                            width: 100%;
                            height: 100%;
                            text-align: center;
                        }
                        body, html {
                        height: 100%;
                        margin: 0;
                        font-family: Arial, sans-serif;
                        background-color: ${colorBackground};
                    }

                    .logo-container {
                        position: relative; 
                        display: table;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        color: ${colorForeground};
                    }

                    .logo-container i {
                        font-size: 450px;
                        margin-bottom: 20px;
                    }

                    .logo-container h1 {
                        font-size: 150px;
                        margin: 0;
                        margin-bottom: 10px;
                    }

                    .logo-container p {
                        font-size: 80px;
                        margin: 0;
                    }

                    .logo-container::after {
                        content: "Pharos WebPro"; 
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) rotate(-45deg); 
                        font-size: 100px;
                        color: rgba(0, 0, 0, 0.1); 
                        font-family: Arial, sans-serif;
                        letter-spacing: 5px;
                        font-weight: bold;
                        pointer-events: none; 
                        z-index: 1;
                    }
                    `}
                </style>
            </section>
        </>
    );
};

export default CandidateV3Area;
