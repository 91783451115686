import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPageArea = () => {
  return (
    <div className="error-page d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 col-md-6 ms-auto order-md-last">
              <div className="error">404</div>
              <h2>Page Not Found </h2>
              <p className="text-md">
                Vous ne trouvez pas ce que vous cherchez ? Prenez un moment et effectuez une recherche ci-dessous ou commencez à partir de notre page d'accueil.
              </p>
              <Link
                to="/"
                className="btn-one w-100 d-flex align-items-center justify-content-between mt-30"
              >
                <span>RETOUR</span>
                <img src="/assets/images/icon/icon_61.svg" alt="icon" />
              </Link>
            </div>
            <div className="col-md-6 order-md-first">
              <img
                src="/assets/images/assets/404.svg"
                alt="error-img"
                className="sm-mt-60"
              />
            </div>
          </div>
        </div>
      </div>
  );
};

export default ErrorPageArea;