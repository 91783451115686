import axiosInstance from './axiosConfig';

export const getListemailAdress = async () => {


    try {
        const response = await axiosInstance.post('/graphql', {
            query: `
                query getEmailAddresses($limit: Int, $nextToken: String) {
                    getEmailAddresses(limit: $limit, nextToken: $nextToken) {
                        items {
                            emailAddress
                            projectId
                        }
                        nextToken
                    }
                }
            `,
            variables: {
                limit: 10, 
                nextToken: null, 
            },
        });
        return response.data;
    } catch (error) {
        console.error("Erreur lors de l'appel GraphQL :", error);
        throw error; 
    }
};
export const getNumberOfMails = async(sourceEmailAddress) =>{
try{
    const response= await axiosInstance.post('/graphql',{
    query:
    `
    query getNumberOfMails($sourceEmailAddress: AWSEmail!) {
        getNumberOfMails(sourceEmailAddress: $sourceEmailAddress) {
                totalEmailsCount
                unreadEmailsCount
        }
    }`,
    variables: {sourceEmailAddress}
})
return response.data.data?.getNumberOfMails;
}catch(error){
    console.error("errror lors de recupration de nombre des emails ",error);
}
};

export const getReceivedMails = async(sourceEmailAddress) =>{
    try{
        const response= await axiosInstance.post('/graphql',{
        query:
        `
            query getReceivedMails($sourceEmailAddress: AWSEmail!, $nextToken: String) {
                getReceivedMails(sourceEmailAddress: $sourceEmailAddress, nextToken: $nextToken) {
                        items {
                            itemId
                            subject
                            sender
                            body
                            time
                            attachments {
                                name
                                attachmentId
                            }
                        }
                }
            }`,
        variables: {
            sourceEmailAddress,
            nextToken :0
        }
    })
  
    return response.data?.data?.getReceivedMails;
    
    }catch(error){
        console.error("errror lors de recupration de nombre des emails ",error);
    }
    };

export const getDraftMails = async(sourceEmailAddress) =>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
                query getDraftMails($sourceEmailAddress: AWSEmail!, $nextToken: String) {
                        getDraftMails(sourceEmailAddress: $sourceEmailAddress, nextToken: $nextToken) {
                                items {
                                    itemId
                                    subject
                                    sender
                                    body
                                    time
                                    attachments {
                                        name
                                        attachmentId
                                    }
                                }
                        }
                    }`,
                variables: 
                {
                sourceEmailAddress,
                nextToken:0
            }
        })
      
        return response.data.data?.getDraftMails;
        
        }catch(error){
            console.error("errror lors de recupration de getDraftMails ",error);
        }
        };

export const getSentMails = async(sourceEmailAddress) =>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
               query getSentMails($sourceEmailAddress: AWSEmail!, $nextToken: String) {
                    getSentMails(sourceEmailAddress: $sourceEmailAddress, nextToken: $nextToken) {
                            items {
                                itemId
                                subject
                                sender
                                body
                                time
                                attachments {
                                    name
                                    attachmentId
                                }
                            }
                    }
                }`,
                variables: 
                {
                sourceEmailAddress,
                nextToken:0
            }
        })
      
        return response.data.data?.getSentMails;
        
        }catch(error){
            console.error("errror lors de recupration de sentEmail ",error);
        }
        };
     export const getAllContact =async(sourceEmailAddress)=>{
            try{
                const response= await axiosInstance.post('/graphql',{
                query:
                `
                   query getContacts($sourceEmailAddress: AWSEmail!, $nextToken: String) {
                        getContacts(sourceEmailAddress: $sourceEmailAddress, nextToken: $nextToken) {
                                items {
                                    itemId
                                    name
                                    emails
                                    surname
                                    phoneNumbers
                                }
                        }
                    }`,
                    variables: 
                    {
                    sourceEmailAddress,
                    nextToken:0
                }
            })
          
            return response.data.data?.getContacts;
            
            }catch(error){
                console.error("errror lors de recupration de contacts ",error);
            }       
                
     };
     export const geAttachements =async(sourceEmailAddress,itemIdn,attachmentId)=>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
               mutation getAttachmentDownloadPresignedUrl($sourceEmailAddress: AWSEmail!, $itemId: String!, $attachmentId: String!) {
                getAttachmentDownloadPresignedUrl(sourceEmailAddress: $sourceEmailAddress, itemId: $itemId, attachmentId: $attachmentId) {
                        url
                }
            }`,
                variables: 
                {
                sourceEmailAddress,
                itemIdn,
                attachmentId
              
            }
        })
      
        return response.data;
        
        }catch(error){
            console.error("errror lors de recupration de contacts ",error);
        }       
            
    };

     export const deleteEmail =async(sourceEmailAddress,itemIds)=>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
               mutation deleteMails($sourceEmailAddress: AWSEmail!, $itemIds: [String!]!) {
                    deleteMails(sourceEmailAddress: $sourceEmailAddress, itemIds: $itemIds)
                }`,
                variables: 
                {
                sourceEmailAddress,
                itemIds
                
              
            }
        })
      
        return response.data;
        
        }catch(error){
            console.error("errror lors de supression de email ",error);
        }       
            
    };
           
 export const mutationSentEmail=async(input)=>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
              mutation sendMail($sourceEmailAddress: AWSEmail!, $input: SendMailInput!) {
                    sendMail(sourceEmailAddress: $sourceEmailAddress, input: $input)
                }`,
                variables:{input}
                
        })
      
        return response.data;
        
        }catch(error){
            console.error("errror lors de supression de email ",error);
        }

    }
    export const addContact =async (input)=>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
             mutation addContact($sourceEmailAddress: AWSEmail!, $input: AddContactInput!) {
                addContact(sourceEmailAddress: $sourceEmailAddress, input: $input)
            }`,
                variables:{input}
                
        })
      
        return response.data;
        
        }catch(error){
            console.error("errror lors de supression de email ",error);
        }
    }