import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../../../api/axiosConfig";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { generateS3PreSignedUploadUrl } from "../../../api/webPackage";
import {setimgLogo} from "../../../redux/packageSlice";

const CandidateV3Area = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imgLogo = useSelector((state) => state.package.s3ImageKey);
  console.log("imgLogo",imgLogo);
  const { projectId } = useParams();
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setUploadError(null); 
  };
 
  const uploadFileToS3 = async () => {
    try {
      const response = await generateS3PreSignedUploadUrl(projectId, file.name);
      console.log("Réponse de l'API pour l'URL pré-signée :", response);
  
      const { url, fields } = response;
      console.log("URL pré-signée :", url);
      console.log("Champs pré-signés :", fields);
  
      const fileData = new FormData();
      fileData.append("key", fields.key);
      fileData.append("policy", fields.policy);
      fileData.append("x-amz-algorithm", fields.xAmzAlgorithm);
      fileData.append("x-amz-credential", fields.xAmzCredential);
      fileData.append("x-amz-date", fields.xAmzDate);
      fileData.append("x-amz-security-token", fields.xAmzSecurityToken);
      fileData.append("x-amz-signature", fields.xAmzSignature);
      fileData.append("file", file);

      console.log("Données FormData envoyées dans la requête POST :");
      for (let pair of fileData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
              console.log("file", file.name);

      }
      console.log("Clé S3 stockée :", fields.key);

      dispatch(setimgLogo(fields.key));
      console.log("Clé S3 stockée :", fields.key);
  
      const uploadResponse = await axios.post(url, fileData);
      console.log("Réponse du téléversement :", uploadResponse);
      dispatch(setimgLogo(fields.key));
      console.log("Clé S3 stockée :", fields.key);
    } catch (error) {
      console.error("Erreur lors de la génération de l'URL pré-signée ou du téléversement :", error);
      if (error.response) {
        console.error("Réponse de l'API :", error.response.data);
        console.error("Statut de l'API :", error.response.status);
      } else {
        console.error("Erreur réseau ou problème de connectivité", error);
      }
    } finally {
      navigate(`/package-com4N2/${projectId}`); 
    }
  };
  

  return (
    <>
      <div className="container">
        <div
          className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center d-flex flex-column justify-content-center align-items-center"
          style={{ paddingTop: '135px', paddingBottom: '30px' }}
        >
          <div className="row w-100">
            <div className="col-12">
              <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                CRÉATION PACKAGE COMMERCIAL
              </h2>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to={`/package-com3/${projectId}`}>
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">4/15</span>
            </div>
          </div>
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h4 className="fw-600">Chargez votre logo</h4>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <div className="upload-btn position-relative d-flex align-items-center justify-content-between mb-25">
                <span className="fw-500 text-dark me-1">Chargez</span>
                <i className="bi bi-file-earmark-arrow-up"></i>
                <input
                  type="file"
                  id="uploadCV"
                  name="uploadCV"
                  className="form-control"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            {uploadError && (
              <div className="col-12 text-center text-danger mb-3">
                {uploadError}
              </div>
            )}
            {uploadMessage && (
              <div className="col-12 text-center text-success mb-3">
                {uploadMessage}
              </div>
            )}
            <div className="col-12">
              <button
                className="btn-eleven fw-500 tran3s d-block mt-20"
                onClick={uploadFileToS3}
                disabled={isUploading || !file}
              >
                {isUploading ? "Téléversement en cours..." : "Continuez"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;


