
import React,{useState,useEffect} from 'react';
import DashboardHeader from '../../header';
import {Link} from "react-router-dom";
import { fetchDownloafile, getWebpackageOrder } from '../../../../../api/webPackage';
import { useParams } from 'react-router-dom'; 
import { useDispatch } from 'react-redux';
import { setProject } from '../../../../../redux/packageSlice';

const Info = ({ setIsOpenSidebar }) => {
  const [isOpenGeneral, setIsOpenGeneral] = useState(false);
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [isOpenLogo, setIsOpenLogo] = useState(true);
  const [projects, setProjects] = useState({});
  const [loading, setLoading] = useState(true);
  const { projectId } = useParams(); 
  const [logoUrl, setLogoUrl] = useState("");
  const dispatch= useDispatch();


  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await getWebpackageOrder(projectId); 
        setProjects(response?.data?.getWebpackageOrder || {});
        const data = response?.data?.getWebpackageOrder || {};
        console.log("data dispatch",data);
       dispatch(setProject(data));
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProject();
  }, [projectId ,dispatch]); 
  

  const file= projects.logo?.logoToCreate?.s3ImageKey;
  console.log('rtg',file);
  useEffect(() => {
    const getLogoUrl = async () => {
        if (file) {
            try {
                const response = await fetchDownloafile(file);
                setLogoUrl(response.data.generateWebpackageOrderS3PreSignedDownloadUrl.url);
            } catch (error) {
                console.error("Erreur lors de la récupération de l'URL pré-signée :", error);
            }
        }
    };

    getLogoUrl();
}, [file]);
  

  const toggleAccordionGeneral = () => setIsOpenGeneral(!isOpenGeneral);
  const toggleAccordionLogo = () => setIsOpenLogo(!isOpenLogo);
  const toggleAccordionGallery = () => setIsOpenGallery(!isOpenGallery);
  
 
  

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
    

        <h2 className="main-title text-center h2-titel"> Pharos WebPro &gt; {projects.projectName}</h2>

        <div className="bg-white card-box border-20 col-12">
              <h4 onClick={toggleAccordionGeneral} style={{ cursor: 'pointer' }}>
                &gt;Informations générales
              </h4>

              {isOpenGeneral && (
                <div className="mb-30">
                  <div className="mt-30">
                    <div className="row">
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <label htmlFor="domainInput">Nom de domaine</label>
                        <input
                          type="text"
                          className="form-control"
                          id="domainInput"
                          value={projects.domainName || ""}
                          placeholder="Nom de domaine"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="companyInput">Nom société</label>
                        <input
                          type="text"
                          className="form-control"
                          id="companyInput"
                          value={projects.website?.globalDetails?.companyName || ""}
                          placeholder="Nom société"
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-md-6">
                        <label htmlFor="addressInput">Adresse</label>
                        <input
                          type="text"
                          className="form-control"
                          id="addressInput"
                          value={projects.website?.globalDetails?.address || ""}
                          placeholder="Adresse"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phoneInput">Numéro de téléphone</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phoneInput"
                          value={projects.website?.globalDetails?.phoneNumber || ""}
                          placeholder="Téléphone"
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-md-12">
                          <h5>Horaires</h5>
                          <div className="horaires-wrapper border p-3" style={{ borderRadius: '10px' }}>
                            <div className="row mb-2">
                              <div className="col"></div>
                              <div className="col text-center">Début matinée</div>
                              <div className="col text-center">Fin matinée</div>
                              <div className="col text-center">Début après-midi</div>
                              <div className="col text-center">Fin après-midi</div>
                            </div>
                            {['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh'].map((dayKey, index) => {
                              const times = projects.website?.globalDetails?.days?.[dayKey] || [];
                              const startMorning = times[0]?.split('-')[0] || '';
                              const endMorning = times[0]?.split('-')[1] || '';
                              const startAfternoon = times[1]?.split('-')[0] || '';
                              const endAfternoon = times[1]?.split('-')[1] || '';
                              
                              const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

                              return (
                                <div className="row mb-2 align-items-center" key={dayKey}>
                                  <div className="col" style={{ width: '100px' }}>{daysOfWeek[index]}</div>
                                  <div className="col">
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      placeholder="Début matinée"
                                      value={startMorning}
                                      readOnly
                                    />
                                  </div>
                                  <div className="col">
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      placeholder="Fin matinée"
                                      value={endMorning}
                                      readOnly
                                    />
                                  </div>
                                  <div className="col">
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      placeholder="Début après-midi"
                                      value={startAfternoon}
                                      readOnly
                                    />
                                  </div>
                                  <div className="col">
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      placeholder="Fin après-midi"
                                      value={endAfternoon}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                    <div className="row">
                      <div className="col-md-12">
                        <h5>A propos</h5>
                        <textarea
                          className="form-control"
                          id="aboutInput"
                          value={projects.website?.about?.paragraph || ""}
                          placeholder="A propos"
                          readOnly
                        />
                      </div>
                    </div>


                    
                    </div>
                  </div>
                </div>
              )}

              <h4 onClick={toggleAccordionLogo} style={{ cursor: 'pointer' }}>
                &gt;Logo
              </h4>
{isOpenLogo  &&(
                <>
                
                      <div className="text-center mb-30">
                        <div className="d-flex justify-content-center mt-30">
                        {logoUrl ? (
                          <img
                           src={logoUrl}
                            alt="logo"
                            style={{ maxWidth: "200px", maxHeight: "200px",position: "center" }}
                            />
                          ) : (
                                                <p>Chargement de l'aperçu du logo...</p>
                                            )}
                        </div>
                        
                      </div>
                    

                  </>
)}
                <h4 onClick={toggleAccordionGallery} style={{ cursor: 'pointer' }}>
                  &gt;Galleries images
                </h4>

                {isOpenGallery && (
                  <>
                  <div className="text-center mb-30">
                    <div className="d-flex justify-content-center mt-30 row">
                      <div className='col-md-3 mb-3'>
                        <div className="gallery-item gallery-item-card">
                          <img src="/assets/images/logo/logo-pharos.png" style={{paddingTop:"30%"}} className='mx-auto d-block ' alt="image1"  width="100" />
                        </div>
                      </div>
                      <div className='col-md-3 mb-3'>
                        <div className="gallery-item gallery-item-card">
                          <img src="/assets/images/logo/logo-pharos.png" style={{paddingTop:"30%"}} className='mx-auto d-block ' alt="image1"  width="100" />
                        </div>
                      </div>
                      <div className='col-md-3 mb-3'>
                        <div className="gallery-item gallery-item-card">
                          <img src="/assets/images/logo/logo-pharos.png" style={{paddingTop:"30%"}} className='mx-auto d-block ' alt="image1"  width="100" />
                        </div>
                      </div>
                      <div className='col-md-3 mb-3'>
                        <div className="gallery-item gallery-item-card">
                          <img src="/assets/images/logo/logo-pharos.png" style={{paddingTop:"30%"}} className='mx-auto d-block ' alt="image1"  width="100" />
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </>
                )}

                  <div className="d-flex justify-content-center mt-4">
                      <Link to={`/package-com1/${projectId}`} className="dash-btn-two tran3s me-3">Modifier le package</Link>
                    </div>
                  </div>

      </div>
    </div>
  );
};

export default Info;











// import React, { useEffect, useState } from 'react';
// import { useParams ,Link } from 'react-router-dom';
// import DashboardHeader from '../../header';
// import { getWebpackageOrder, upsertWebpackageOrder } from '../../../../../api/webPackage';
// import GeneralInfo from './ComponentInfo/GeneralInfo';
// import LogoSection from './ComponentInfo/LogoSection';
// import GallerySection from './ComponentInfo/GallerySection';

// const Info = ({ setIsOpenSidebar }) => {
//   const { projectId } = useParams();
//   const [projects, setProjects] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [generalInfo, setGeneralInfo] = useState({});
//   const [logoInfo, setLogoInfo] = useState({});
//   const [galleryInfo, setGalleryInfo] = useState({});

//   useEffect(() => {
//     const fetchProject = async () => {
//       try {
//         const response = await getWebpackageOrder(projectId);
//         setProjects(response?.data?.getWebpackageOrder || {});
//       } catch (error) {
//         console.error('Error fetching projects:', error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchProject();
//   }, [projectId]);
// console.log("generalInfo",generalInfo);
//   const handleSubmit = async () => {
//     const input = {
//       ...generalInfo,
//       logo: logoInfo,
//       gallery: galleryInfo,
//     };

//     try {
//       await upsertWebpackageOrder(input);
//       console.log("Data submitted successfully!");
//     } catch (error) {
//       console.error("Submission error:", error);
//     }
//   };

//   return (
//     <div className="dashboard-body">
//       <div className="position-relative">
//         <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
//         <h2 className="main-title text-center h2-titel">
//           Pharos WebPro &gt; {projects?.projectName || 'Loading...'}
//         </h2>
//         <div className="bg-white card-box border-20 col-12">
//           <GeneralInfo onChange={(data) => setGeneralInfo(data)} />
//           <LogoSection onChange={(data) => setLogoInfo(data)} />
//           <GallerySection onChange={(data) => setGalleryInfo(data)} />
//           <div className="d-flex justify-content-center mt-4">
//             <Link to="/package-com1" className="dash-btn-two tran3s me-3">
//               Modifier le package
//             </Link>
//           </div>
//         </div>
       
//       </div>
//     </div>
//   );
// };


// export default Info;
