import React from 'react';
import DashboardHeader from '../../header';

const InformationGenerale = ({ setIsOpenSidebar }) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className=" d-flex flex-column align-items-center main-title h2-titel"> Ma facturation &gt;Projet nom &gt;Information générale</h2>

        <div className="bg-white card-box border-20 col-12">
          <form >
          <h4 className="mb-4"><u>Votre logo</u></h4>
            <div className="d-flex flex-column align-items-center mb-40">
              <div className="user-avatar-setting d-flex flex-column align-items-center mb-30">
                <img src="/assets/images/logo/logo-pharos.png" alt="logo" width="100" />
                <div className="upload-btn position-relative dash-btn-two tran3s text-white me-3 mt-3">
                  Charger photo
                  <input type="file" id="uploadImg" name="uploadImg" placeholder="" />
                </div>
              </div>
            </div>


            <h4 className="mb-4" ><u>Vos Informations</u></h4>

           


            <div className="row mb-4">
              <div className="col-md-12">
                <label htmlFor="addressInput">Adresse</label>
                <input type="text" className="form-control" id="addressInput" placeholder="xxxxxx" />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="postalCodeInput">Code Postal</label>
                <input type="text" className="form-control" id="postalCodeInput" placeholder="xxxxxx" />
              </div>
              <div className="col-md-6">
                <label htmlFor="cityInput">Ville</label>
                <input type="text" className="form-control" id="cityInput" placeholder="xxxxxx" />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="emailInput">Adresse mail</label>
                <input type="text" className="form-control" id="emailInput" placeholder="xxxxxx" />
              </div>
              <div className="col-md-6">
                <label htmlFor="phoneInput">Numéro de téléphone</label>
                <input type="text" className="form-control" id="phoneInput" placeholder="xxxxxx" />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="capitalInput">Capital social</label>
                <input type="text" className="form-control" id="capitalInput" placeholder="xxxxxx" />
              </div>
              <div className="col-md-6">
                <label htmlFor="siretInput">Siret</label>
                <input type="text" className="form-control" id="siretInput" placeholder="xxxxxx" />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="nafInput">NAF</label>
                <input type="text" className="form-control" id="nafInput" placeholder="xxxxxx" />
              </div>
              <div className="col-md-6">
                <label htmlFor="tvaInput">N° TVA Intracommunautaire</label>
                <input type="text" className="form-control" id="tvaInput" placeholder="xxxxxx" />
              </div>
            </div>

            <h4 className="mb-4" ><u>Vos conditions générales</u></h4>
            <div className="row mb-4">
              <div className="col-md-12">
                <textarea className="form-control" rows="3" name="description"></textarea>
              </div>
            </div>

            <h4 className="mb-4" ><u>Vos coordonnées bancaires</u></h4>
            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="accountNameInput">Nom du compte</label>
                <input type="text" className="form-control" id="accountNameInput" placeholder="xxxxxx" />
              </div>
              <div className="col-md-6">
                <label htmlFor="bankNameInput">Nom de la banque</label>
                <input type="text" className="form-control" id="bankNameInput" placeholder="xxxxxx" />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <label htmlFor="ibanInput">IBAN</label>
                <input type="text" className="form-control" id="ibanInput" placeholder="xxxxxx" />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="bicInput">BIC/SWIFT</label>
                <input type="text" className="form-control" id="bicInput" placeholder="xxxxxx" />
              </div>
              <div className="col-md-6">
                <label htmlFor="bankAddressInput">Adresse de la banque</label>
                <input type="text" className="form-control" id="bankAddressInput" placeholder="xxxxxx" />
              </div>
            </div>

            <div className="d-md-flex justify-content-md-start">
              <button className="dash-btn-two tran3s me-3">Enregistrer tout</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InformationGenerale;
