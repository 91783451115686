import React ,{useState,useEffect} from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';
import { getWebpackageOrders } from '../../../../api/webPackage';


const Communication = ({setIsOpenSidebar}) => {

  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getWebpackageOrders();
        setProjects(response?.data?.getWebpackageOrders?.items || []);
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
    console.log(fetchProjects())

  }, []);
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <div className="d-flex align-items-center justify-content-between mb-40 lg-mb-30">
          <h2 className="main-title m0 h2-titel">Pharos WebPro</h2>          
        </div>
        <div className="bg-white card-box border-20">
          <div className="row justify-content-center">
         
             <div className="col-12">
                  {projects.map((project) => (
                         <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                        <Link to={`/info-projet/${project.projectId}`}>{project.projectName}</Link>
                            

                        </button>
                        ))}
                      
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                         <Link to="/paiement-package-web">Commander un nouveau projet</Link>
                        </button>
                        </div>
                         
          </div>
        </div>
      
          
      </div>      
    </div>
  );
};

export default Communication;
// import React, { useEffect, useState } from 'react';
// import DashboardHeader from '../header';
// import { Link } from 'react-router-dom';
// import { Button, Card, CardContent, Typography, CircularProgress, Grid } from '@mui/material';
// import { getWebpackageOrders } from '../../../../api/webPackage';

// const Communication = ({ setIsOpenSidebar }) => {
//   const [projects, setProjects] = useState([]);
//   const [loading, setLoading] = useState(true);

//      useEffect(() => {
//      const fetchProjects = async () => {
//        try {
//          const response = await getWebpackageOrders();
//          setProjects(response?.data?.getWebpackageOrders?.items || []);
//        } catch (error) {
//          console.error('Error fetching projects:', error);
//        } finally {
//          setLoading(false);
//        }
//      };

//      fetchProjects();
//      console.log(fetchProjects())

//    }, []);
//   return (
//     <div className="dashboard-body">
//       <div className="position-relative">
//         {/* Header */}
//         <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

//         <div className="d-flex align-items-center justify-content-between mb-40 lg-mb-30">
//           <h2 className="main-title m0 h2-title">Pharos WebPro</h2>
//         </div>

//         <div className="bg-white card-box border-20">
//           {loading ? (
//             <div className="text-center">
//               <CircularProgress />
//             </div>
//           ) : (
//             <Grid container spacing={2} justifyContent="center">
//               {projects.map((project) => (
//                 <Grid item xs={12} sm={6} md={4} key={project.projectId}>
//                   <Card elevation={3}>
//                     <CardContent>
//                       <Typography variant="h6" gutterBottom>
//                         {project.projectName}
//                       </Typography>
//                       <Typography variant="body2" color="textSecondary">
//                         Status: {project.projectStatus}
//                       </Typography>
//                       <Button
//                         className="btn-eleven fw-500 tran3s d-block mt-20 no-hover"
//                         variant="contained"
//                         component={Link}
//                         to={`/info-projet/${project.projectId}`}
//                         style={{ marginTop: '10px' }}
//                       >
//                         Voir le projet
//                       </Button>
//                     </CardContent>
//                   </Card>
//                 </Grid>
//               ))}
//             </Grid>
//           )}
//           <div className="mt-3">
//             <Grid container justifyContent="center">
//               <Grid item xs={12} sm={6} md={4}>
//                 <Button
//                   variant="contained"
//                   className="btn-eleven fw-500 tran3s d-block mt-20 no-hover"
//                   fullWidth
//                   component={Link}
//                   to="/paiement-package-web"
//                 >
//                   Commander un nouveau projet
//                 </Button>
//               </Grid>
//             </Grid>
//           </div>
//         </div>
//       </div>
//       <style>
//       {`
//           .no-hover:hover {
//             color: white !important;  /* Change la couleur du texte en blanc */
//             text-decoration: none !important;  /* Désactive le soulignement (ou autre) au survol */
//           }
//         `}
//       </style>
//     </div>
//   );
// };

// export default Communication;
        