import React from 'react';
import DashboardHeader from '../../header';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, Typography, Card, Stack } from '@mui/material';

const DemarchesCreate = ({ setIsOpenSidebar }) => {

  const location = useLocation();
  const { project } = location.state || {}; 

  console.log('Project data:', project);

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <Typography
            variant="h4"
            align="center"
            gutterBottom
            className="main-title h2-title"
          >
            <span className="mb-3">Démarche récapitulatif</span>
            <br />
            <Typography
              variant="h5"
              color="textSecondary"
              sx={{ mt: 2 }}
            >
              {project?.projectName || ''}
            </Typography>
          </Typography>

        {project && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <Card
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                p: 3,
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
             
              <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                <Typography variant="h6">Statut du projet :</Typography>
                <Typography
                  variant="h6"
                  color="success.main"
                  sx={{ ml: 1 }}
                >
                  {project?.projectStatus || ''}
                </Typography>
              </Stack>
            </Card>
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
          <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 3, borderRadius: 2, boxShadow: 3 }}>
            <Button
              component={Link}
              to="/rdv"
              variant="contained"
              sx={{
                backgroundColor: 'rgb(25, 175, 120)',
                color: '#fff',
                borderRadius: '20px',
                padding: '10px 20px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'rgb(25, 175, 120)',
                  color: '#fff',
                },
              }}
            >
              Prendre rdv avec nos experts
            </Button>
          </Card>

          <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 3, borderRadius: 2, boxShadow: 3 }}>
          <Button
            component={Link}
            to="/dashboard-mes-demarches-info-societe"
            state={{ project }} // Transmettre le projet ici
            variant="contained"
            sx={{
              backgroundColor: 'rgb(25, 175, 120)',
              color: '#fff',
              borderRadius: '20px',
              padding: '10px 20px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgb(25, 175, 120)',
                color: '#fff',
              },
            }}
          >
            Informations sur la société
          </Button>

          </Card>


          <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 3, borderRadius: 2, boxShadow: 3 }}>
            <Button
              component={Link}
              to="/mes-demarches-document-a-signer"
              variant="contained"
              sx={{
                backgroundColor: 'rgb(25, 175, 120)',
                color: '#fff',
                borderRadius: '20px',
                padding: '10px 20px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'rgb(25, 175, 120)',
                  color: '#fff',
                },
              }}
            >
              Documents à signer
            </Button>
          </Card>
        </Box>
      </div>
    </div>
  );
};

export default DemarchesCreate;
