import React, { useEffect, useState } from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, Alert, Box } from '@mui/material';
import { getListemailAdress } from '../../../../api/emailService';

const MailIndex = ({ setIsOpenSidebar }) => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAdressmail = async () => {
        try {
            const response = await getListemailAdress();
            const emailItems = response?.data?.getEmailAddresses?.items || [];
            console.log('Données extraites :', emailItems);
            setEmails(emailItems);
            
        } catch (error) {
            console.error('Erreur lors de la récupération des adresses e-mails :', error);
            setError('Une erreur est survenue lors de la récupération des adresses e-mails.');
        } finally {
            setLoading(false);
        }
    };
    fetchAdressmail();
}, []);


  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <div className="d-flex align-items-center justify-content-between mb-40 lg-mb-30">
          <h2 className="main-title mb-0 h2-titel">Boîtes mail</h2>
        </div>

        <div className="bg-white card-box border-20 p-4">
           {emails.length === 0 ? (
            <Box mt={3}>
                <Alert severity="info">Aucune adresse e-mail disponible.</Alert>
            </Box>
        ) : (
            emails.map((email, index) => (
                <Box key={index} display="flex" justifyContent="center" mt={2}>
                  
                   <Link to={`/boites-mail-dashb/${email.emailAddress}`} style={{ textDecoration: 'none' }}>
                      <Button
                          className="btn-eleven fw-500 tran3s d-block mt-20"
                          style={{ textTransform: 'none', color:'white'}}
                      >
                          {email.emailAddress || `Adresse mail ${index + 1}`}
                      </Button>
                  </Link>

                </Box>
            ))
        )}
        
          <Box display="flex" justifyContent="center" mt={3}>
                  <div className="d-flex justify-content-center mt-3">                  
                      <Link to="/ajouter-adresse-email" className="dash-btn-two tran3s me-3">Commander une nouvelle adresse</Link>
                  </div>
            </Box>
        </div>
      </div>
    </div>
  );
};

export default MailIndex;
