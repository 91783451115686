import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const SessionExpiredDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Session Expirée</DialogTitle>
      <DialogContent>
        Votre session a expiré. Vous allez être redirigé vers la page de connexion.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Se connecter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionExpiredDialog;
