import React from "react";
import {Link} from "react-router-dom";

const JobAlertItem = ({
  title,
  location,
  category,
  duration,
  found,
  time,
  onClick 
}) => {
  return (
    <tr onClick={onClick}> 
      <td className='text-center'>
        <div>{title}</div>
        <div>{location}</div>
      </td>
      <td className='text-center'>
        <div className={`job-type fw-500 ${duration === "Part-Time" ? "part-time" : ""}`}>
          <Link
            to="/mes-demarches-create"
            style={{ textDecoration: "none" }}
          >
            {category}
          </Link>
        </div>
      </td>
    </tr>
  );
};


export default JobAlertItem;
