import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TextField, Button, Checkbox, FormControlLabel, CircularProgress } from '@mui/material';

import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import axiosInstance from "../../../api/axiosConfig";
const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

console.log(poolData); 

const userPool = new CognitoUserPool(poolData);

const LoginPage = () => {
  const navigate = useNavigate(); 

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleLogin = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        console.log("Login success:", result);
        setIsLoading(false);
        const token = result.getIdToken().getJwtToken();
      
      localStorage.setItem("authToken", token);  

      axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
        navigate('/dashboard');
      },
      onFailure: (err) => {
        console.error("Login failed:", err);
        setIsLoading(false);
        setError(err.message || JSON.stringify(err));
      },
    });
  };

  return (
    
      <div className="login-page">
        {/* Header Section */}
        <div className="hero-banner-two position-relative">
          <div className="container">
            <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
              <div className="row">
                <div className="col-lg-12 col-md-8">
                  <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Login</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* Login Form Section */}
        <div className="container">
          <div className="user-data-form">
            <div className="text-center">
              <h2>Salut, Bon Retour !</h2>
              <p>
                Vous n'avez toujours pas de compte?{" "}
                <Link to="/register">Inscrivez-vous</Link>
              </p>
            </div>
            <div className="form-wrapper m-auto">
              <form onSubmit={handleLogin} className="mt-10">
                <div className="row">
                  <div className="col-12">
                    <div className="input-group-meta position-relative mb-25">
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                        className="login-input"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group-meta position-relative mb-20">
                      <TextField
                        fullWidth
                        label="Mot de passe"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        margin="normal"
                        required
                      />
                      <span className="placeholder_icon">
                        <span className="passVicon">
                          <img
                            src="/assets/images/icon/icon_60.svg"
                            alt=""
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className=" d-flex justify-content-between align-items-center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={rememberMe}
                            onChange={() => setRememberMe(!rememberMe)}
                            color="primary"
                          />
                        }
                        label="Garder ma session active"
                      />
                      <Link to="#">Mot de passe oublié?</Link>
                    </div>
                  </div>
                  <div className="col-12">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className="btn-eleven1 fw-500 tran3s d-block mt-20"
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularProgress size={24} /> : 'Connexion'}
                    </Button>
                  </div>
                </div>
              </form>
              {error && <div className="error-msg text-center mt-2">{error}</div>}
  
              <div className="d-flex align-items-center mt-30 mb-10">
                <div className="line"></div>
                <span className="pe-3 ps-3" style={{ whiteSpace: 'nowrap' }}>Ou se connecter avec</span>
                <div className="line"></div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Link
                    to="#"
                    className="social-use-btn d-flex align-items-center justify-content-center tran3s w-100 mt-10"
                  >
                    <img
                      src="/assets/images/icon/google.png"
                      alt="Google Icon"
                    />
                    <span className="ps-2">Connexion avec Google</span>
                  </Link>
                </div>
                <div className="col-md-6">
                  <Link
                    to="#"
                    className="social-use-btn d-flex align-items-center justify-content-center tran3s w-100 mt-10"
                  >
                    <img
                      src="/assets/images/icon/microsoft.png"
                      alt="Microsoft Icon"
                    />
                    <span className="ps-2">Connexion avec Microsoft</span>
                  </Link>
                </div>
              </div>
  
              <p className="text-center mt-10">
                Vous n'avez pas de compte?{" "}
                <Link to="/register" className="fw-500">
                  Inscrivez-vous
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      
  );
};

export default LoginPage;
