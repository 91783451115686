"use client";
import React from "react";
import { Link } from "react-router-dom"; // Use react-router-dom for routing
import { FaUserAlt, FaUserFriends, FaSlack } from 'react-icons/fa';

const CandidateV3Area = () => {
  return (
    <>

<div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Choisir son statut</h2>
                                {/* <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <section className="blog-section-two pt-20 xl-pt-30 lg-pt-100 pb-150 xl-pb-130 lg-pb-80">
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to="/activite-statut-s3p" className="btn btn-success">
                {"< Précédent"}
              </Link>
              <span className="mx-3">5/5</span>
              <Link to="/activite-statut-s3p2" className="btn btn-success">
                {"Suivant >"}
              </Link>
            </div>
            <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
              <h2 className="fw-600">
                Quels sont les modes de rémunérations souhaités ?
              </h2>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12">
              <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/activite-statut-s3p2" className="d-block">
                  <FaUserAlt className="me-2" /> Versement d’un salaire
                </Link>
              </button>
              <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/activite-statut-s3p3" className="d-block">
                  <FaUserFriends className="me-2" /> Versement de dividendes
                </Link>
              </button>
              <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/activite-statut-s3p2" className="d-block">
                  <FaSlack className="me-2" /> Les deux
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* CandidateFilterModal is omitted as it's not available */}
      <style jsx>{`

      `}</style>
    </>
  );
};

export default CandidateV3Area;
