import axios from 'axios';
import { useHistory } from 'react-router-dom';  
import { useState } from 'react';

const axiosInstance = axios.create({
  baseURL: 'https://zwf6mlywrrgpzanodo5a76omne.appsync-api.eu-west-1.amazonaws.com', 
});

const token = localStorage.getItem("authToken");
if (token) {
  axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
}

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
    
      localStorage.removeItem("authToken");
      alert('Votre session a expiré. Vous allez être redirigé vers la page de connexion.');
      
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
