import { BrowserRouter, Routes, Route,Switch  } from 'react-router-dom';
import { useState,useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ErrorPageArea from './Components/error/error-page-area' 
import Home from './layouts/Home';
import DashboardMain from './Components/dashboard/index';
import MesDemarchesPage from './Components/dashboard/employ/mes-demarches/index/index';
import MesDemarchesCreate from './Components/dashboard/employ/mes-demarches/create/create';
import MesDemarchesinfoSociete from './Components/dashboard/employ/mes-demarches/info-societe/index';
import DocumentSigner from './Components/dashboard/employ/mes-demarches/document-a-signer/index';
import MaCommunication from './Components/dashboard/employ/ma-communication/index';
import Ged from './Components/dashboard/employ/ged/index';
import GedAddFile from './Components/dashboard/employ/ged/ged-index';
import BoitesMail from './Components/dashboard/employ/boites-mail/index';
import AddEmail from './Components/dashboard/employ/boites-mail/add-email';
import BoitesMessage from './Components/dashboard/employ/boites-mail/boite-message';
import MesAchats from './Components/dashboard/employ/mes-achats/index';
import Profile from './Components/dashboard/employ/profile/index';
import Contact from './layouts/Contact';
import Create_soc from './layouts/create_societe/create_soc';
import Create_soc1 from './layouts/create_societe/create_soc1';
import Create_soc2 from './layouts/create_societe/create_soc2';
import Create_soc3 from './layouts/create_societe/create_soc3';
import Create_soc4 from './layouts/create_societe/create_soc4';
import Create_soc5 from './layouts/create_societe/create_soc5';
import Create_soc6 from './layouts/create_societe/create_soc6';
import Rendez_vous1 from './layouts/rendez-vous/rendez-vous1';
import Rendez_vous2 from './layouts/rendez-vous/rendez-vous2';
import Rendez_vous3 from './layouts/rendez-vous/rendez-vous3';
import Rendez_vous4 from './layouts/rendez-vous/rendez-vous4';
import Rendez_vous5 from './layouts/rendez-vous/rendez-vous5';
import Rendez_vous6 from './layouts/rendez-vous/rendez-vous6';
import Rendez_vous7 from './layouts/rendez-vous/rendez-vous7';
import Activite_statut from './layouts/activite-statut/activite-statut';
import Activite_statut_p from './layouts/activite-statut/activite-statut-p';
import Activite_statut_p1 from './layouts/activite-statut/activite-statut-p1';
import Activite_statut_p2a from './layouts/activite-statut/activite-statut-p2a';
import Activite_statut_p2b from './layouts/activite-statut/activite-statut-p2b';
import Activite_statut_p2c from './layouts/activite-statut/activite-statut-p2c';
import Activite_statut_pa from './layouts/activite-statut/activite-statut-pa';
import Activite_statut_psral from './layouts/activite-statut/activite-statut-psarl';
import Activite_statut_pso_i from './layouts/activite-statut/activite-statut-pso-i';
import Activite_statut_psas from './layouts/activite-statut/activite-statut-psas';
import Activite_statut_pso_p from './layouts/activite-statut/activite-statut-pso-p';
import Activite_statut_s from './layouts/activite-statut/activite-statut-s';
import Activite_statut_s1 from './layouts/activite-statut/activite-statut-s1';
import Activite_statut_s2 from './layouts/activite-statut/activite-statut-s2';
import Activite_statut_s3m from './layouts/activite-statut/activite-statut-s3m';
import Activite_statut_s3m1 from './layouts/activite-statut/activite-statut-s3m1';
import Activite_statut_s3p from './layouts/activite-statut/activite-statut-s3p';
import Activite_statut_s3p1 from './layouts/activite-statut/activite-statut-s3p1';
import Activite_statut_s3p2 from './layouts/activite-statut/activite-statut-s3p2';
import Activite_statut_s3p3 from './layouts/activite-statut/activite-statut-s3p3';
import Package_com from './layouts/package-com/package-com';
import Package_com1 from './layouts/package-com/package-com1';
import Package_com2 from './layouts/package-com/package-com2';
import Package_com3 from './layouts/package-com/package-com3';
import Package_com4N from './layouts/package-com/package-com4N';
import Package_com4N1 from './layouts/package-com/package-com4N1';
import Package_com4N2 from './layouts/package-com/package-com4N2';
import Package_com4N1V1 from './layouts/package-com/package-com4N1V1';
import Package_com4Y from './layouts/package-com/package-com4Y';
import Package_com5 from './layouts/package-com/package-com5';
import Package_com6 from './layouts/package-com/package-com6';
import Package_com7 from './layouts/package-com/package-com7';
import Package_com8 from './layouts/package-com/package-com8';
import Package_com8AI from './layouts/package-com/package-com8AI';
import Package_com8T from './layouts/package-com/package-com8T';
import Package_com9 from './layouts/package-com/package-com9';
import Package_com10 from './layouts/package-com/package-com10';
import Package_com11 from './layouts/package-com/package-com11';
import Package_com12 from './layouts/package-com/package-com12';
import Package_com13 from './layouts/package-com/package-com13';
import Package_com14 from './layouts/package-com/package-com14';
import Statut_jur from './layouts/statut_juridique/statut_jur';
import Statut_jur_simulateur from './layouts/statut_juridique/statut_jur_simuler';
import EnSavoir from './layouts/statut_juridique/statut_jur_en_sav';
import EnSavoirAsso from './layouts/statut_juridique/statut_jur_en_sav_asso';
import MaFacturation from './Components/dashboard/employ/ma-facturation/index'
import InformationGenerale from './Components/dashboard/employ/ma-facturation/info-generale/index';
import Factures from './Components/dashboard/employ/ma-facturation/factures/index';
import FacturesCreate from './Components/dashboard/employ/ma-facturation/factures/create';
import Devis from './Components/dashboard/employ/ma-facturation/devis/index';
import DevisCreate from './Components/dashboard/employ/ma-facturation/devis/create';
import Achats from './Components/dashboard/employ/ma-facturation/achats/index';
import ClientsFournisseurs from './Components/dashboard/employ/ma-facturation/clients-fournisseurs/index';
import Register from './layouts/Register';
import Login from './layouts/Login';
import InfoProjet from './Components/dashboard/employ/ma-communication/projet/projet';
import IndexFact from './Components/dashboard/employ/ma-facturation/IndexFact';

import SimulatorInfo from './Components/frontend/statut-juridique/simulator-info';
import FacturesIndex from './Components/dashboard/employ/ma-facturation/factures/facture';
import CandidateItem from './Components/dashboard/employ/mes-achats/candidate-item';
import AchatCreate from './Components/dashboard/employ/mes-achats/achatCreate';
import ProjetCreate from './Components/dashboard/employ/ma-facturation/creer_nouveau_projet';
import IndexProjet from './Components/dashboard/employ/ma-facturation/indexProjet';
import FacturesCreate1 from './Components/dashboard/employ/ma-facturation/factures/createEchance';
import CreateEchance from './Components/dashboard/employ/ma-facturation/factures/createEchance';
import InfoSocieteClient from './Components/dashboard/employ/ma-facturation/factures/infoSocieteClient';
import InfoSocieteCl from './Components/dashboard/employ/ma-facturation/factures/infosocietecl';
import ConditionGenal from './Components/dashboard/employ/ma-facturation/factures/ConditionGenal';
import ChecherSoc from './Components/dashboard/employ/ma-facturation/factures/chercherSociete';
import Paiment_Pack from './layouts/package-com/package-paiement';
import CreateAchat from './Components/dashboard/employ/mes-achats/createAchat';
import Statut_jur_en_savoir from './Components/frontend/statut-juridique/statut-juridique-area';
import IndexGed from './Components/dashboard/employ/ged/index_ged';
import CorbeilleGed from './Components/dashboard/employ/ged/corbeille';
import IndexRdv from './Components/dashboard/employ/rdv/index-rdv';
import ScrollToTop from './layouts/ScrollToTop';
import SessionExpiredDialog from './Components/SessionExpiredDialog';
function App() {
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    const sessionStatus = localStorage.getItem('sessionExpired'); 
    if (sessionStatus === 'true') {
      setSessionExpired(true);
    }
  }, []);

  const handleCloseDialog = () => {
    setSessionExpired(false);
  };
  return (    
    <>
    <div>
        <BrowserRouter>
            <div>
                <ScrollToTop /> 
                <Routes>
                      <Route path="/" index element={ <Home></Home> }                           
                      />
                      <Route path="/dashboard" element={<DashboardMain></DashboardMain> }                        
                      />                                      
                      <Route path="/mes-demarches" element={<MesDemarchesPage></MesDemarchesPage> }/>                                      
                      <Route path="/mes-demarches-create" element={<MesDemarchesCreate></MesDemarchesCreate> } />                      
                      <Route path="/dashboard-mes-demarches-info-societe" element={<MesDemarchesinfoSociete></MesDemarchesinfoSociete> } />    
                      <Route path="/mes-demarches-document-a-signer" element={<DocumentSigner></DocumentSigner> }/>                                                        
                      <Route path="/ma-communication" element={<MaCommunication></MaCommunication> } /> 
                      <Route path="/info-projet/:projectId" element={<InfoProjet />} />
                      <Route path="/ged/:projectId" element={<Ged></Ged> } />
                      <Route path="/ged-index" element={<IndexGed></IndexGed> } />  
                      <Route path="/ged-corbeille" element={<CorbeilleGed></CorbeilleGed> } />                                                
                      {/* <Route path="/ged/index" element={<GedAddFile></GedAddFile> } />                       */}
                      <Route path="/boites-mail-dashb/:emailAddress" element={<BoitesMessage></BoitesMessage> } />
                      <Route path="/boites-mail" element={<BoitesMail></BoitesMail> } /> 
                      <Route path="/ajouter-adresse-email" element={<AddEmail></AddEmail> } />                   
                      <Route path="/mes-achats" element={<MesAchats></MesAchats> } />
                      <Route path="/profile" element={<Profile></Profile> } />
                      <Route path="/contact" element={<Contact></Contact> } />
                      <Route path="/create_soc" element={<Create_soc></Create_soc> } />
                      <Route path="/create_soc1" element={<Create_soc1></Create_soc1> } />
                      <Route path="/create_soc2" element={<Create_soc2></Create_soc2> } />
                      <Route path="/create_soc3" element={<Create_soc3></Create_soc3> } />
                      <Route path="/create_soc4" element={<Create_soc4></Create_soc4> } />
                      <Route path="/create_soc5" element={<Create_soc5></Create_soc5> } />
                      <Route path="/create_soc6" element={<Create_soc6></Create_soc6> } />
                      <Route path="/rendez-vous1" element={<Rendez_vous1></Rendez_vous1> } />
                      <Route path="/rendez-vous2" element={<Rendez_vous2></Rendez_vous2> } />
                      <Route path="/rendez-vous3" element={<Rendez_vous3></Rendez_vous3> } />
                      <Route path="/rendez-vous4" element={<Rendez_vous4></Rendez_vous4> } />
                      <Route path="/rendez-vous5" element={<Rendez_vous5></Rendez_vous5> } />
                      <Route path="/rendez-vous6" element={<Rendez_vous6></Rendez_vous6> } />
                      <Route path="/rendez-vous7" element={<Rendez_vous7></Rendez_vous7> } />
                      <Route path="/activite-statut" element={<Activite_statut></Activite_statut> } />
                      <Route path="/activite-statut-p" element={<Activite_statut_p></Activite_statut_p> } />
                      <Route path="/activite-statut-p1" element={<Activite_statut_p1></Activite_statut_p1> } />
                      <Route path="/activite-statut-p2a" element={<Activite_statut_p2a></Activite_statut_p2a> } />
                      <Route path="/activite-statut-p2b" element={<Activite_statut_p2b></Activite_statut_p2b> } />
                      <Route path="/activite-statut-p2c" element={<Activite_statut_p2c></Activite_statut_p2c> } />
                      <Route path="/activite-statut-pa" element={<Activite_statut_pa></Activite_statut_pa> } />
                      <Route path="/activite-statut-psarl" element={<Activite_statut_psral></Activite_statut_psral> } />
                      <Route path="/activite-statut-psas" element={<Activite_statut_psas></Activite_statut_psas> } />
                      <Route path="/activite-statut-pso-i" element={<Activite_statut_pso_i></Activite_statut_pso_i> } />
                      <Route path="/activite-statut-pso-p" element={<Activite_statut_pso_p></Activite_statut_pso_p> } />
                      <Route path="/activite-statut-s" element={<Activite_statut_s></Activite_statut_s> } />
                      <Route path="/activite-statut-s1" element={<Activite_statut_s1></Activite_statut_s1> } />
                      <Route path="/activite-statut-s2" element={<Activite_statut_s2></Activite_statut_s2> } />
                      <Route path="/activite-statut-s3m" element={<Activite_statut_s3m></Activite_statut_s3m> } />
                      <Route path="/activite-statut-s3m1" element={<Activite_statut_s3m1></Activite_statut_s3m1> } />
                      <Route path="/activite-statut-s3p" element={<Activite_statut_s3p></Activite_statut_s3p> } />
                      <Route path="/activite-statut-s3p1" element={<Activite_statut_s3p1></Activite_statut_s3p1> } />
                      <Route path="/activite-statut-s3p2" element={<Activite_statut_s3p2></Activite_statut_s3p2> } />
                      <Route path="/activite-statut-s3p3" element={<Activite_statut_s3p3></Activite_statut_s3p3> } />
                      <Route path="/package-com" element={<Package_com></Package_com> } />
                      <Route path="/paiement-package-web" element={<Paiment_Pack></Paiment_Pack> } />
                      <Route path="/package-com1/:projectId" element={<Package_com1></Package_com1> } />
                      <Route path="/package-com2/:projectId" element={<Package_com2></Package_com2> } />
                      <Route path="/package-com3/:projectId" element={<Package_com3></Package_com3> } />
                      <Route path="/package-com4N/:projectId" element={<Package_com4N></Package_com4N> } />
                      <Route path="/package-com4N1/:projectId" element={<Package_com4N1></Package_com4N1> } />
                      <Route path="/package-com4N1V1/:projectId" element={<Package_com4N1V1></Package_com4N1V1> } />
                      <Route path="/package-com4N2/:projectId" element={<Package_com4N2></Package_com4N2> } />
                      <Route path="/package-com4Y/:projectId" element={<Package_com4Y></Package_com4Y> } />
                      <Route path="/package-com5/:projectId" element={<Package_com5></Package_com5> } />
                      <Route path="/package-com6/:projectId" element={<Package_com6></Package_com6> } />
                      <Route path="/package-com7/:projectId" element={<Package_com7></Package_com7> } />
                      <Route path="/package-com8/:projectId" element={<Package_com8></Package_com8> } />
                      <Route path="/package-com8AI/:projectId" element={<Package_com8AI></Package_com8AI> } />
                      <Route path="/package-com8T/:projectId" element={<Package_com8T></Package_com8T> } />
                      <Route path="/package-com9/:projectId" element={<Package_com9></Package_com9> } />
                      <Route path="/package-com10/:projectId" element={<Package_com10></Package_com10> } />
                      <Route path="/package-com11/:projectId" element={<Package_com11></Package_com11> } />
                      <Route path="/package-com12/:projectId" element={<Package_com12></Package_com12> } />
                      <Route path="/package-com13/:projectId" element={<Package_com13></Package_com13> } />
                      <Route path="/package-com14/:projectId" element={<Package_com14></Package_com14> } />
                      <Route path="/ma-facturation" element={<MaFacturation></MaFacturation> } />
                      <Route path="/ma-facturation-index" element={<IndexFact></IndexFact> } />
                      <Route path="/simulator-info" element={<SimulatorInfo></SimulatorInfo> } />
                       <Route path="/ma-facturation-information-generale" element={<InformationGenerale/>} />
                       <Route path="/ma-facturation-infos-societe-client" element={<InfoSocieteCl/>} />
                      <Route path="/ma-facturation-factures" element={<Factures></Factures> } />
                      <Route path="/ma-facturation-factures-create" element={<FacturesCreate></FacturesCreate> } />
                      <Route path="/ma-facturation-factures-conditions-générales" element={<ConditionGenal></ConditionGenal> } />
                      <Route path="/ma-facturation-factures-rechercher-societe" element={<ChecherSoc></ChecherSoc> } />
                      <Route path="/ma-facturation-devis" element={<Devis></Devis> } />
                      {/* <Route path="/ma-facturation-liste_factures" element={<Factures></FacturesIndex> } /> */}
                      <Route path="/ma-facturation-devis-create" element={<DevisCreate></DevisCreate> } />
                      <Route path="/creer-nouveau-projet" element={<IndexProjet/> } />
                      <Route path="/ma-facturation-achats" element={<Achats></Achats> } />
                      <Route path ="/rdv" element={<IndexRdv></IndexRdv>}/>
                      <Route path="/ma-facturation-achats-create" element={<CreateAchat></CreateAchat> } />
                      <Route path="/ma-facturation-clients-fournisseurs" element={<ClientsFournisseurs></ClientsFournisseurs> } />
                      <Route path="*" element={<ErrorPageArea></ErrorPageArea>} />  
                      <Route path="/register" element={<Register></Register> } />                          
                      <Route path="/login" element={<Login></Login> } />   
                      <Route path="/statut-juridique" element={<Statut_jur></Statut_jur> } /> 
                      <Route path="/statut-juridique-simulateur" element={<Statut_jur_simulateur></Statut_jur_simulateur> } />                                             
                      <Route path="/en-savoir-plus" element={<EnSavoir></EnSavoir> } />                       
                      <Route path="/en-savoir-plus-asso" element={<EnSavoirAsso></EnSavoirAsso> } /> 
                      <Route path="/en-savoir-plus-staut_juridique" element={<Statut_jur_en_savoir></Statut_jur_en_savoir> } />                       
                      </Routes>                            
              </div>
          </BrowserRouter>
          <SessionExpiredDialog open={sessionExpired} onClose={handleCloseDialog} />

      </div>
    </>
  );
}

export default App;
