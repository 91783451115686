import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from '@mui/lab';
import { FaCheckCircle, FaStar, FaThumbsUp, FaInfoCircle } from 'react-icons/fa';
import { Grid, Paper, Typography, Button, Container } from '@mui/material';
import { Stack } from '@mui/material';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BalanceIcon from '@mui/icons-material/Balance';
import TaskIcon from '@mui/icons-material/Task';
/* import AccordionItem from "../Components/accordion/accordion-item"; */
import AccordionItem from "../../accordion/accordion-item";
import '../../../css/statut-juridique.css';



const companyData = [
  { id: 1, name: 'SAS / SASU', description: 'Description of SAS / SASU' },  
  { id: 2, name: 'SARL / EURL', description: 'Description of SARL / EURL' },
  { id: 3, name: 'SCI', description: 'Description of SCI' },
  { id: 4, name: 'Micro-Entreprise (Auto-entrepreneur)', description: 'Description of Micro-Entreprise' },
  { id: 5, name: 'Association', description: 'Description of Association' },
];

function PositionedTimeline() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
 
 
 
  return (
    <>
      <Timeline
  sx={{
    maxWidth: "800px", 
    margin: "0 auto", 
    [`& .${timelineItemClasses.root}:before`]: isSmallScreen
      ? {
          flex: 0,
          padding: 0,
        }
      : undefined,
  }}
  position={isSmallScreen ? undefined : "alternate-reverse"}
>
        <TimelineItem sx={{ alignItems: "center" }}>
          <TimelineSeparator>
            <TimelineConnector
              sx={{ height: "50px", background: "transparent" }}
            />
            <TimelineDot
              sx={{
                backgroundColor: "rgb(162, 221, 199)",
                borderColor: "white",
                borderWidth: 5,
                borderStyle: "solid",
                width: 45,
                height: 45,
                margin: 0,
              }}
            />
            <TimelineConnector sx={{ height: "50px" }} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} sx={{ padding: 1 }}>
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <ReceiptLongIcon
                  sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                />
                <Typography align="right" style={{ fontSize:'font-size: 1rem !important' }}>
                Étape 1              
                 </Typography>
              </Stack>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ alignItems: "center" }}>
          <TimelineSeparator>
            <TimelineConnector sx={{ height: "50px" }} />
            <TimelineDot
              sx={{
                backgroundColor: "rgb(162, 221, 199)",
                borderColor: "white",
                borderWidth: 5,
                borderStyle: "solid",
                width: 45,
                height: 45,
                margin: 0,
              }}
            />
            <TimelineConnector sx={{ height: "50px" }} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} sx={{ padding: 1 }}>
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <RecordVoiceOverIcon
                  sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                />
                <Typography align="left">
                Étape 2
                </Typography>
              </Stack>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ alignItems: "center" }}>
          <TimelineSeparator>
            <TimelineConnector sx={{ height: "50px" }} />
            <TimelineDot
              sx={{
                backgroundColor: "rgb(162, 221, 199)",
                borderColor: "white",
                borderWidth: 5,
                borderStyle: "solid",
                width: 45,
                height: 45,
                margin: 0,
              }}
            />
            <TimelineConnector sx={{ height: "50px" }} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} sx={{ padding: 1 }}>
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <AccountBalanceIcon
                  sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                />
                <Typography align="left">
                Étape 4
                </Typography>
              </Stack>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ alignItems: "center" }}>
          <TimelineSeparator>
            <TimelineConnector sx={{ height: "50px" }} />
            <TimelineDot
              sx={{
                backgroundColor: "rgb(162, 221, 199)",
                borderColor: "white",
                borderWidth: 5,
                borderStyle: "solid",
                width: 45,
                height: 45,
                margin: 0,
              }}
            />
            <TimelineConnector sx={{ height: "50px" }} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} sx={{ padding: 1 }}>
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <BalanceIcon
                  sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                />
                <Typography align="left">
                   Étape 4
                </Typography>
              </Stack>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ alignItems: "center" }}>
          <TimelineSeparator>
            <TimelineConnector sx={{ height: "50px" }} />
            <TimelineDot
              sx={{
                backgroundColor: "rgb(162, 221, 199)",
                borderColor: "white",
                borderWidth: 5,
                borderStyle: "solid",
                width: 45,
                height: 45,
                margin: 0,
              }}
            />
            <TimelineConnector
              sx={{ height: "50px", background: "transparent" }}
            />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} sx={{ padding: 1 }}>
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <TaskIcon
                  sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                />
                <Typography align="left">Étape 5</Typography>
              </Stack>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </>
  );
}



const CompanyCard = ({ item }) => {
  return (
    <Paper
      elevation={6}
      style={{
        padding: '10px',
        boxShadow: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
        borderRadius: '5px',
        border: '2px solid rgb(25, 175, 120)',
        textAlign: 'center',
        transition: 'transform 0.3s, box-shadow 0.3s',
      }}
      className="card_solution"
    >
    
    <div style={{ marginBottom: '20px' }}>
        <FaInfoCircle size={50} style={{ color: '#007bff' }} />
      </div>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
        {item.name}
      </Typography>
      <Typography variant="body2" color="textSecondary" paragraph>
        {item.description}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <Link to="/en-savoir-plus">
          <Button variant="contained" color="primary">
            En savoir plus
          </Button>
        </Link>
        <Link to="/create_soc">
          <Button className="btn-success" style={{color:"white"}} >
            Créer
          </Button>
        </Link>
      </div>
    </Paper>
  );
};




// CompanyListItem Component
// const CompanyListItem = ({ item }) => {
//   return (
//     <div className="list-item border-0 shadow-lg p-3 mb-3">
//       <h5 className="fw-600">{item.name}</h5>
//       <p>{item.description}</p>
//       <div className="button-group mt-3">
//         <Link to="/more-info" className="btn btn-primary me-2">En savoir plus</Link>
//         <Link to="/create_soc" className="btn btn-success">Créer</Link>
//       </div>
//     </div>
//   );
// };

// Main Component
const StatutJuridiqueArea = ({ style_2 = false }) => {

  const cardStyle = {
    width: '100%',
    padding: '10px',
    boxShadow: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    border: '2px solid rgb(25, 175, 120)',
    textAlign: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
  };

  const rowStyle = {
    padding: '40px',
  };
  const [jobType, setJobType] = useState(style_2 ? "list" : "grid");

  return (
    <div className="hero-banner-two position-relative background_section">
      <div className="container">
        <div className="position-relative pb-80 lg-pb-10" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Titre</h2>
              <p className="text-md mt-35 mb-50 lg-mb-30 fadeInUp text-center">
                Le statut juridique utilisé pour la création d'une entreprise désigne la forme légale sous laquelle une entreprise est constituée, déterminant ainsi ses obligations fiscales, sociales et légales, ainsi que la responsabilité de ses associés ou dirigeants.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section-two pt-20 xl-pt-0lg-pt-0 pb-5" style={{ backgroundColor: '#FFF' }}>        
          <div className="row">
            <div className="col-12">
              <div className="wrapper">
              <div className="pt-2">
                <Container>
                  <Typography variant="h2" align="center" gutterBottom style={{ marginBottom: '40px' }}>
                    <h2 className="title_staut">Choisissez le statut juridique de votre future entreprise</h2>
                  </Typography>
                  <Grid container spacing={3} justifyContent="center" className="row gx-xxl-5">
                    {companyData.map((item) => (
                      <Grid
                        key={item.id}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        className="col-lg-4 col-md-6 mt-4"
                      >
                        <CompanyCard item={item} />
                      </Grid>
                    ))}
                  </Grid>
                </Container>
              </div>

                <br />
                <br />
                <div className="blog-section-two px-4 pt-20 pb-20 background_section">
                  <div className="title-one text-center mb-20 lg-mb-10 wow fadeInUp">
                    <h2 className="title_staut font-500">
                      Vous ne savez pas quel statut choisir ou estimez vos futures charges,
                      utilisez notre simulateur ou prenez rendez-vous avec nos experts
                    </h2>
                  </div>
                  <div className="row justify-content-center" style={rowStyle}>
                    <div className="col-md-6 col-lg-4 d-flex mb-4">
                      <div className="card" style={cardStyle}>
                        <img
                          src="/assets/images/blog/entreprise.jpg"
                          className="card-img-top"
                          alt="Image 1"
                          style={{ height: '200px', objectFit: 'cover' }}
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title fw-600">Choisir votre forme juridique</h5>
                          <p className="card-text">texte</p>
                          <Link to="/activite-statut" className="btn btn-success">
                            Lancer le simulateur
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4 d-flex mb-4">
                      <div className="card  mx-auto" style={cardStyle}>
                        <img
                          src="/assets/images/blog/entreprise.jpg"
                          className="card-img-top"
                          alt="Image 2"
                          style={{ height: '200px', objectFit: 'cover' }}
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title fw-600">Echanger avec un expert</h5>
                          <p className="card-text">texte (prix)</p>
                          <Link to="/rendez-vous1" className="btn btn-success">
                            Lancer le simulateur
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4 d-flex mb-4">
                      <div className="card mx-auto" style={cardStyle}>
                        <img
                          src="/assets/images/blog/entreprise.jpg"
                          className="card-img-top"
                          alt="Image 3"
                          style={{ height: '200px', objectFit: 'cover' }}
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title fw-600">Estimer vos charges</h5>
                          <p className="card-text">texte</p>
                          <Link to="/statut-juridique-simulateur" className="btn btn-success">
                            Lancer le simulateur
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="title-one text-center wow fadeInUp mt-4">
                  <h2 className="fw-600">Les étapes de création d’une entreprise</h2>
                </div>
                <PositionedTimeline />

                {/* <div className="title-one text-center mb-30 lg-mb-10 p-4 wow fadeInUp background_section">
                  <h2 className="fw-600 mb-4">Questions fréquentes</h2>
                  <div className="accordion">
                    <div className="accordion-item">
                      <h3 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#question1" aria-expanded="true">
                          Question 1 ?
                        </button>
                      </h3>
                      <div id="question1" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                        <div className="accordion-body">
                          Texte réponse
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question2" aria-expanded="false">
                          Question 2 ?
                        </button>
                      </h3>
                      <div id="question2" className="accordion-collapse collapse" aria-labelledby="headingTwo">
                        <div className="accordion-body">
                          Texte réponse
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question3" aria-expanded="false">
                          Question 3 ?
                        </button>
                      </h3>
                      <div id="question3" className="accordion-collapse collapse" aria-labelledby="headingThree">
                        <div className="accordion-body">
                          Texte réponse
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <section className="text-feature-three position-relative background_section mb-4" /* pt-225 xl-pt-200 lg-pt-150 md-pt-100 */>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 order-lg-last ms-auto">
                          <div className="wow fadeInRight">
                          <div className="title-two">                        
                              <h2 className="fw-600 color-blue text-center">Questions fréquentes</h2>
                          </div>
                          <div className="accordion accordion-style-one color-two mt-40" id="accordionOne">
                            <div className="accordion accordion-style-one color-two mt-40" id="accordionOne">
                              <AccordionItem id='one' /* isShow={true} */ title="Question 1 ?" desc="Texte réponse" parent='accordionOne' />
                              <AccordionItem id='two' title="Question 2 ?" desc="Texte réponse" parent='accordionOne' />
                              <AccordionItem id='three' title="Question 3 ?" desc="Texte réponse" parent='accordionOne' />                    
                            </div>                   
                          </div>                    
                          </div>
                      </div>                
                    </div>
                  </div>
                </section>

                <div className="title-one text-center mb-50 wow fadeInUp">
                  <h2 className="fw-600 mb-4">Pourquoi nous choisir ?</h2>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="feature-item text-center p-4 border rounded shadow-sm">
                          <FaStar size={40} className="text-warning mb-3" />
                          <h5 className="fw-600">Argument 1</h5>
                          <p>text</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="feature-item text-center p-4 border rounded shadow-sm">
                          <FaThumbsUp size={40} className="text-warning mb-3" />
                          <h5 className="fw-600">Argument 2</h5>
                          <p>text</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="feature-item text-center p-4 border rounded shadow-sm">
                          <FaCheckCircle size={40} className="text-warning mb-3" />
                          <h5 className="fw-600">Argument 3</h5>
                          <p>text</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="title-one text-center  mb-30 lg-mb-10 wow fadeInUp">
                  <h2 className="fw-600">Nos tarifs</h2>
                </div>

                <div className="table-responsive mb-50 px-4">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Formalités</th>
                        <th>Nos Tarifs</th>
                        <th>Concurrents en ligne</th>
                        <th>Avocats</th>
                        <th>Experts-comptables</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>formalité 1</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                      </tr>
                      <tr>
                        <td>formalité 2</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                      </tr>
                      <tr>
                        <td>formalité 3</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                      </tr>
                      <tr>
                        <td>formalité 4</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                      </tr>
                      <tr>
                        <td>formalité 5</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                        <td>texte + prix</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>        
      </section>
    </div>
    
  );
};

export default StatutJuridiqueArea;
