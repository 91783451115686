import React, { useState ,useEffect} from 'react';
import { FaFolder, FaTrash, FaEllipsisV, FaFile ,FaSearch} from 'react-icons/fa';
import { Modal, Button, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import DashboardHeader from '../header';
import { fetchGetCompanyProject } from '../../../../api/companyService';
import { getWebpackageOrder } from '../../../../api/webPackage';

const DocumentSigner = ({ setIsOpenSidebar }) => {
  const [folders, setFolders] = useState([
    { name: 'Dossier 1', subfolders: ['Dossier 2'], files: [] },
    { name: 'Dossier 11', subfolders: [], files: [] }
  ]);
  const handleClose = () => setShowModal(false);
  const handleCloseDeplacer = () => setShowModalDeplcer(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeplacer,setShowModalDeplcer]=useState(false);
  const [showPartage,setShowModalPartage]=useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleShowDeplacer = () => setShowModalDeplcer(true);
  const handlShowPartage=()=>setShowModalPartage(true);
  const handleClosePartage = () => setShowModalPartage(false);
  const [project,setProject]=useState({});
  const [loading,setLoading]=useState();
  const {projectId}=useParams();


useEffect(() => {
  const fetchProject = async () => {
    try {
      const response = await getWebpackageOrder(projectId); 
      setProject(response?.data?.getWebpackageOrder || {});
      console.log(response?.data?.getWebpackageOrder);
     console.log("project",project);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchProject();
}, [projectId]);
  
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <h2 className=" d-flex flex-column align-items-center main-title h2-titel"> GED &gt; Projet nom &gt;{project.projectName}</h2>

      <div className="row">

      <div className="sidebar col-12 col-md-4">
      <h2 className=" d-flex flex-column  main-title h2-titel">Pharos GED</h2>
      <ul className="folder-tree">
          <li>Ma GED
            <ul>
              {folders.map((folder, index) => (
                <li key={index}>
                  {folder.name}
                  {folder.subfolders.length > 0 && (
                    <ul>
                      {folder.subfolders.map((subfolder, i) => (
                        <li key={i}>{subfolder}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </li>
          <li><FaTrash  /><Link to ="/ged-corbeille" style={{textDecoration:"none"}}>   Corbeille</Link></li>
        </ul>
      </div>

      <div className="card content-area col-12 col-md-8">

        <div className="search-bar mt-0 position-relative">
          <input type="text" placeholder="   Rechercher dans ma GED" className="search-input" />
          <span className="search-icon">
        
          </span>
        </div>


        
        <div className="actions d-flex align-items-center">
          <button>Créer un dossier</button>
          <button onClick={handleShowModal}>Charger un fichier</button>
        </div>

        <div className="breadcrumb">Ma GED &gt; Dossier 1 &gt; Dossier 2</div>
        
        <div className="row mt-4">
  {[
    { name: "Nom dossier 1", type: "folder" },
    { name: "Nom dossier 2", type: "folder" },
    { name: "Nom dossier 3", type: "folder" },
  ].map((item, index) => (
    <div key={index} className="col-md-4 mb-3">
      <Card className="h-40">
        <Card.Body className="position-relative">
          <Card.Title className='fadeInUp'>
            <FaFolder /> {item.name}
          </Card.Title>
          
          <div className="action-dots float-end" style={{ top: '0', right: '0' }}>
            <button
              className="action-btn dropdown-toggle position-absolute"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ top: '0px', right: '0px' }}
            >
              <FaEllipsisV />
            </button>
            <ul className="dropdown-menu dropdown-menu-end custom-dropdown-menu">
              <li>
                <Link className="dropdown-item" to="#">
                  <img
                    src="https://img.icons8.com/ios-glyphs/24/000000/pencil.png"
                    alt="Renommer icon"
                    className="lazy-img"
                    style={{ width: '20px', height: '20px' }}
                  /> Renommer
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  <img
                    src="https://img.icons8.com/ios-glyphs/24/000000/copy.png"
                    alt="Créer une copie icon"
                    className="lazy-img"
                    style={{ width: '20px', height: '20px' }}
                  /> Créer une copie
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#" onClick={handleShowDeplacer}>
                  <img
                    src="https://img.icons8.com/ios-glyphs/24/000000/move.png"
                    alt="Déplacer icon"
                    className="lazy-img"
                    style={{ width: '20px', height: '20px' }}
                  /> Déplacer
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#" onClick={handlShowPartage}>
                  <img
                    src="https://img.icons8.com/ios-glyphs/24/000000/share.png"
                    alt="Partager icon"
                    className="lazy-img"
                    style={{ width: '20px', height: '20px' }}
                  /> Partager par mail
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  <img
                    src="https://img.icons8.com/ios-glyphs/24/000000/download.png"
                    alt="Télécharger icon"
                    className="lazy-img"
                    style={{ width: '20px', height: '20px' }}
                  /> Télécharger
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  <img
                    src="https://img.icons8.com/ios-glyphs/24/000000/delete.png"
                    alt="Supprimer icon"
                    className="lazy-img"
                    style={{ width: '20px', height: '20px' }}
                  /> Supprimer
                </Link>
              </li>
            </ul>
          </div>
        </Card.Body>
      </Card>
    </div>
  ))}
  
  {/* Files section */}
  {[{ name: "Nom fichier 1", type: "file" },
    { name: "Nom fichier 2", type: "file" }
  ].map((item, index) => (
    <div key={index} className="col-md-4 mb-3">
      <Card className="h-40">
        <Card.Body className="position-relative">
          <Card.Title className='fadeInUp'>
            <FaFile /> {item.name}
          </Card.Title>
          <div className="action-dots float-end" style={{ top: '', right: '0' }}>
          <button
                        className="action-btn dropdown-toggle position-absolute"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ top: '0px', right: '0px' }}
                      >
              <FaEllipsisV />
            </button>
            <ul className="dropdown-menu dropdown-menu-end custom-dropdown-menu">
              <li>
                <Link className="dropdown-item" to="#">
                  <img
                    src="https://img.icons8.com/ios-glyphs/24/000000/pencil.png"
                    alt="Renommer icon"
                    className="lazy-img"
                    style={{ width: '20px', height: '20px' }}
                  /> Renommer
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  <img
                    src="https://img.icons8.com/ios-glyphs/24/000000/copy.png"
                    alt="Créer une copie icon"
                    className="lazy-img"
                    style={{ width: '20px', height: '20px' }}
                  /> Créer une copie
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#" onClick={handleShowDeplacer}>
                  <img
                    src="https://img.icons8.com/ios-glyphs/24/000000/move.png"
                    alt="Déplacer icon"
                    className="lazy-img"
                    style={{ width: '20px', height: '20px' }}
                  /> Déplacer
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#" onClick={handlShowPartage}>
                  <img
                    src="https://img.icons8.com/ios-glyphs/24/000000/share.png"
                    alt="Partager icon"
                    className="lazy-img"
                    style={{ width: '20px', height: '20px' }}
                  /> Partager par mail
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  <img
                    src="https://img.icons8.com/ios-glyphs/24/000000/download.png"
                    alt="Télécharger icon"
                    className="lazy-img"
                    style={{ width: '20px', height: '20px' }}
                  /> Télécharger
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  <img
                    src="https://img.icons8.com/ios-glyphs/24/000000/delete.png"
                    alt="Supprimer icon"
                    className="lazy-img"
                    style={{ width: '20px', height: '20px' }}
                  /> Supprimer
                </Link>
              </li>
            </ul>
          </div>
        </Card.Body>
      </Card>
    </div>
  ))}
</div>


      </div>
{/* modal add file */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Charger un fichier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Sélectionnez un fichier à charger :</p>
          <input type="file" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Charger
          </Button>
        </Modal.Footer>
      </Modal>
      {/* modal deplacer */}
      <Modal show={showDeplacer} onHide={handleCloseDeplacer}>
      <Modal.Header closeButton>
        <Modal.Title>Déplacer </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <li>Ma GED
      <ul>
        {folders.map((folder, index) => (
          <li key={index}>
            {folder.name}
            {folder.subfolders.length > 0 && (
              <ul>
                {folder.subfolders.map((subfolder, i) => (
                  <li key={i}>{subfolder}</li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      </li>
      </Modal.Body>
      <Modal.Footer>
       
       
        <Link
          to="/ma-facturation-factures-create"
                      style={{
                        backgroundColor: '#19AF78',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        textDecoration: 'none',
                        transition: 'all 0.3s ease'
                      }}
                      onClick={handleCloseDeplacer}
                    >
                      Déplacer
                    </Link>
      </Modal.Footer>
      </Modal>
      {/* modal paratger  */}
      <Modal show={showPartage} onHide={handleClosePartage}>
      <Modal.Header closeButton>
        <Modal.Title>Partager </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <input
          type="text"
          
          placeholder="Entrez une ou plusieurs adresses e-mail"
          className="form-control"
        />
   </Modal.Body>
      <Modal.Footer>
       
       
        <Link
          to=""
                      style={{
                        backgroundColor: '#19AF78',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        textDecoration: 'none',
                        transition: 'all 0.3s ease'
                      }}
                      onClick={handleClosePartage}
                    >
                      Partager
                    </Link>
      </Modal.Footer>
      </Modal>
      <style jsx>{`
        .dashboard-body {
          display: flex;
        }
        .sidebar {
          width: 250px;
          background-color: rgb(25, 175, 120) !important;
          color: white;
          padding: 20px;
        }
          .card{
                    background-color: white;

          }
        .folder-tree {
          list-style: none;
          padding-left: 0;
        }
        .folder-tree li {
          margin-bottom: 10px;
        }
        .content-area {
          flex: 1;
          padding: 20px;
        }
        .search-bar input {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
          border-radius: 5px;
          border: 1px solid #ccc;
        }
        .actions {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;
        }
        .actions button {
          padding: 10px 15px;
          background-color: #ccc;
          border: none;
          cursor: pointer;
          border-radius: 5px;
        }
        .breadcrumb {
          margin-bottom: 20px;
        }

        .search-bar {
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%; /* Full width */
  padding: 10px 30px 10px 10px; /* Padding for space */
  border: 1px solid #ccc;
  border-radius: 4px;
}
@media (max-width: 768px) {
  .breadcrumb {
    font-size: 14px; /* Adjust breadcrumb font size */
  }

  .search-bar input {
    padding: 8px; /* Reduce padding for mobile */
  }

  .card-title {
    font-size: 16px; /* Adjust font size of card titles */
  }

  .actions {
    flex-direction: column; /* Stack buttons vertically */
    gap: 5px; /* Reduce space between buttons */
  }
}



      `}</style>
      </div>
    </div>
    </div>
  );
};

export default DocumentSigner;
