import React, { useState } from "react";
import { Link } from "react-router-dom";

const CandidateV1Area = ({ style_2 = false }) => {
  const [jobType, setJobType] = useState(style_2 ? "list" : "grid");
  const [showPass, setShowPass] = useState(false);

  const validateForm = (values) => {
    const errors = {};

    if (!values.prenom) {
      errors.prenom = "Prénom requis";
    }
    if (!values.nom) {
      errors.nom = "Nom requis";
    }
    if (!values.email) {
      errors.email = "Email requis";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email invalide";
    }
    if (!values.phone) {
      errors.phone = "Numéro de téléphone requis";
    }

    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const values = {
      prenom: formData.get("prenom"),
      nom: formData.get("nom"),
      email: formData.get("email"),
      phone: formData.get("phone"),
    };

    const errors = validateForm(values);
    if (Object.keys(errors).length === 0) {
      alert("Form submitted successfully!");
    } else {
      alert("Validation errors: " + JSON.stringify(errors));
    }
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Vos coordonnées pour vous contacter</h2>
                <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">Ces informations nous permettront de vous contacter pour répondre à vos questions</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="registration-section position-relative pt-100 lg-pt-80 pb-150 lg-pb-80" style={{ backgroundColor: '#FFF' }}>
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to="/rendez-vous1" className="btn btn-success">
                {"< Précédent"}
              </Link>
              <span className="mx-3">1/5</span>
              <Link to="/rendez-vous3" className="btn btn-success">
                {"Suivant >"}
              </Link>
            </div>
          </div>

          <div className="user-data-form">
            <div className="form-wrapper m-auto">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group-meta position-relative mb-25">
                      <label htmlFor="prenom">Prénom*</label>
                      <input
                        type="text"
                        id="prenom"
                        name="prenom"
                        placeholder="Jean"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group-meta position-relative mb-25">
                      <label htmlFor="nom">Nom*</label>
                      <input
                        type="text"
                        id="nom"
                        name="nom"
                        placeholder="Dupont"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group-meta position-relative mb-25">
                      <label htmlFor="email">Email*</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="jean.dupont@example.com"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group-meta position-relative mb-25">
                      <label htmlFor="phone">Numéro de téléphone*</label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder="0123456789"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center mt-4">
                    <button
                      type="submit"
                      className="btn-eleven1 fw-500 tran3s d-block mt-20"
                    >
                      <Link to="/rendez-vous3" className="text-white">Continuer</Link>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV1Area;
