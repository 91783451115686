import React, { useEffect,useState } from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';
import { getWebpackageOrders } from '../../../../api/webPackage';

const NouveauEmail = ({ setIsOpenSidebar }) => {
  const [projects, setProjects] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getWebpackageOrders();
        setProjects(response?.data?.getWebpackageOrders?.items || []);
      } catch (error) {
        console.error('Error fetching email addresses:', error);
        setError('Une erreur est survenue lors de la récupération des adresses e-mails.');
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, [])
  const styles = {
    customInput: {
      height: '40px',
      padding: '10px',
      fontSize: '16px',
    },
    plusIcon: {
      fontSize: '24px',
    },
    btnPrimary: {
      height: '40px',
      padding: '0 15px',
      marginLeft: '10px', 
    },
    formControl: {
      flexGrow: 1,
    },
  };

  
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <Link to="/boites-mail">{"< Précédent"}</Link>
        <h2 className="main-title text-center h2-titel">
          Choisissez de nouvelles adresses mail
        </h2>

        <div className="bg-white card-box border-20">
          <div className="d-flex justify-content-center mt-4">
            <div className="col-md-6">
              <select
                className="form-control"
                style={{ width: '100%' }}
                aria-label="Sélectionner un projet WebPro"
              >
                <option value="" selected>
                  Sélectionner un projet WebPro
                </option>
                {projects.map((project, index) => (
                  <option key={index} value={project.projectId}>
                    {project.projectName} 
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div className="col-md-6 d-flex">
              <input
                type="text"
                className="form-control"
                style={styles.customInput}
                placeholder="Entrer le préfixe de votre adresse mail"
              />

              <button
                className="btn btn-primary d-flex align-items-center justify-content-center"
                style={styles.btnPrimary}
              >
                <span className="plus-icon" style={styles.plusIcon}>
                  +
                </span>
              </button>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <Link to="/paiement-package-web" className="btn btn-success">
              Acheter
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NouveauEmail;
