import { Accordion, AccordionDetails, AccordionSummary, Drawer, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { fetchGetCompanyProject, fetchUpSertCompany } from '../../../../../api/companyService';
import AddAssocie from '../../../../../layouts/AddAssocie';
import DashboardHeader from '../../header';
import Swal from 'sweetalert2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';




const InformationsSociete = ({ setIsOpenSidebar }) => {
  const location = useLocation();
  const { project } = location.state || {};

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);


  const [updatedProject, setupdatedProject] = useState({
    projectId: '',
    projectName: '',
    legalStatus: '',
    projectStatus: '',
    filesUploadedByAccountancyFirm: [],
  });

  const [company, setCompany] = useState({
    name: '',
    address: '',
    phone: '',
    email: '',
    meetingBookingUrl: null,
    socialCapitalAmount: 0,
    socialCapitalCurrency: '',
    capitalStockAmount: 0,
    capitalStockCurrency: '',
  });

  const [partners, setPartners] = useState([
    {
      partnerId: '',
      name: '',
      isMainLeader: false,
      firstNames: [''],
      dateOfBirthday: '',
      placeOfBirthday: '',
      nationality: '',
      professionalSituation: '',
      socialSecurityNumber: '',
      spouseIdentity: {
        name: '',
        maidenName: '',
        firstNames: [''],
        dateOfBirthday: '',
        placeOfBirthday: '',
        nationality: '',
      },
      files: null,
    }
  ]);


  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        if (project?.projectId) {
          const result = await fetchGetCompanyProject(project?.projectId);
          console.log(result);

          if (result) {
            setupdatedProject({
              projectId: result.projectId,
              projectName: result.projectName,
              legalStatus: result.legalStatus,
              projectStatus: result.projectStatus,
              filesUploadedByAccountancyFirm: result.filesUploadedByAccountancyFirm || [],
            });

            setCompany({
              name: result.companyDetail.name,
              address: result.companyDetail.address,
              phone: result.companyDetail.phone,
              email: result.companyDetail.email,
              meetingBookingUrl: result.companyDetail.meetingBookingUrl,
              socialCapitalAmount: result.companyDetail.socialCapitalAmount,
              socialCapitalCurrency: result.companyDetail.socialCapitalCurrency,
              capitalStockAmount: result.companyDetail.capitalStockAmount,
              capitalStockCurrency: result.companyDetail.capitalStockCurrency,
            });

            if (result.companyPartners) {
              const updatedPartners = result.companyPartners.map((partner, index) => ({
                ...partner,
                isMainLeader: index === 0, // Définit le premier partenaire comme dirigeant
              }));
              setPartners(updatedPartners);
            } else {
              setPartners([
                {
                  partnerId: '',
                  name: '',
                  isMainLeader: false,
                  firstNames: [''],
                  dateOfBirthday: '',
                  placeOfBirthday: '',
                  nationality: '',
                  professionalSituation: '',
                  socialSecurityNumber: '',
                  spouseIdentity: {
                    name: '',
                    maidenName: '',
                    firstNames: [''],
                    dateOfBirthday: '',
                    placeOfBirthday: '',
                    nationality: '',
                  },
                  files: null,
                },
              ]);
            }
            
          }
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjectData();
  }, [project?.projectId]); 



  
  const handleCompanyChange = (e) => {
    const { id, value } = e.target;

    setCompany((prevData) => ({
      ...prevData,
      [id]: value, 
    }));
  };

  const handleNewPartnerChange = (e) => {
    const { id, value } = e.target;
  
    setPartners((prev) => {
      const newPartner = prev.find((p) => p.partnerId === 'newPartner') || {
        partnerId: 'newPartner',
      };
  
      return [
        ...prev.filter((p) => p.partnerId !== 'newPartner'), 
        { ...newPartner, [id]: value },
      ];
    });
  };
  
  const handlePartnerChange = (e, partnerId) => {
    const { id, value } = e.target;
  
    setPartners((prevPartners) =>
      prevPartners.map((partner) => {
        if (partner.partnerId === partnerId) {
          if (id.startsWith('spouse')) {
            const spouseField = id.replace('spouse', ''); 
            const fieldKey = spouseField.charAt(0).toLowerCase() + spouseField.slice(1);
            return {
              ...partner,
              spouseIdentity: {
                ...partner.spouseIdentity,
                [fieldKey]: value,
              },
            };
          }
          return { ...partner, [id]: value };
        }
        return partner;
      })
    );
  };
  
  
  
  
  const handleIconClick = (status) => {

  }; 

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {

    }
    setShowModal(false);
  };
  const handleIconClick1 = (iconClass) => {
    if (iconClass.includes("text-success")) {
      setDrawerOpen(true);
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  const [isOpenGeneral, setIsOpenGeneral] = useState(false);
  const [isOpenEntreprise, setIsOpenEntreprise] = useState(false);
  const [isOpenDocument, setIsOpenDocument] = useState(false);


  const toggleAccordionGeneral = () => setIsOpenGeneral(!isOpenGeneral);
  const toggleAccordionEntreprise = () => setIsOpenEntreprise(!isOpenEntreprise);
  const toggleAccordionDocument = () => setIsOpenDocument(!isOpenDocument);
  const [showModalAddAssocie, setShowModalAddAssocie] = useState(false);

  const handleOpenAddAssocie = () => setShowModalAddAssocie(true);
  const handleCloseAddAssocie = () => setShowModalAddAssocie(false);
  const handleAddAssocie = () => {

    handleCloseAddAssocie();
  };

  

  const handleSubmit = async () => {
    let partnerIdCounter = 1; 
    const input = {
      projectId: updatedProject?.projectId,
      legalStatus: updatedProject?.legalStatus || "SASU",
      companyDetail: {
        name: company?.name,
        address: company?.address,
        phone: company?.phone,
        email: company?.email,
        socialCapitalAmount: company?.socialCapitalAmount,
        socialCapitalCurrency: company?.socialCapitalCurrency,
        capitalStockAmount: company?.capitalStockAmount,
        capitalStockCurrency: company?.capitalStockCurrency,
      },
      companyPartners: partners?.map((partner, index) => {
        const partnerId = partner?.partnerId || `partnerId${partnerIdCounter++}`;
        return {
          partnerId,
          name: partner?.name,
          isMainLeader: index === 0, 
          firstNames: Array.isArray(partner?.firstNames) ? partner?.firstNames : [partner?.firstNames],
          dateOfBirthday: partner?.dateOfBirthday,
          placeOfBirthday: partner?.placeOfBirthday,
          nationality: partner?.nationality,
          professionalSituation: partner?.professionalSituation,
          socialSecurityNumber: partner?.socialSecurityNumber,
          spouseIdentity: partner?.spouseIdentity || null,
        };
      }),
      
    };
  
  
    try {
      console.log("input", { input });
      const result = await fetchUpSertCompany(input);
  
      Swal.fire({
        icon: 'success',
        title: updatedProject?.projectId ? 'Mise à jour réussie' : 'Création réussie',
        text: 'Les données de l’entreprise ont été soumises avec succès.',
      }).then(() => {
        window.location.reload();
      });
  
      setupdatedProject({
        projectId: '',
        projectName: '',
        legalStatus: '',
        projectStatus: '',
        filesUploadedByAccountancyFirm: [],
      });
  
      setCompany({
        name: '',
        address: '',
        phone: '',
        email: '',
        meetingBookingUrl: '',
        socialCapitalAmount: '',
        socialCapitalCurrency: '',
        capitalStockAmount: '',
        capitalStockCurrency: '',
      });
  
      setPartners([{
        partnerId: '',
        name: '',
        isMainLeader: false,
        firstNames: [''],
        dateOfBirthday: '',
        placeOfBirthday: '',
        nationality: '',
        professionalSituation: '',
        socialSecurityNumber: '',
        spouseIdentity: {
          name: '',
          maidenName: '',
          firstNames: [''],
          dateOfBirthday: '',
          placeOfBirthday: '',
          nationality: '',
        },
        files: null,
      }]);
  
      console.log("Submit successful:", result);
    } catch (error) {
      console.error("Error while submitting company data:", error);
  
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: `Échec de la soumission des données : ${error.message}`,
      });
    }
  };
  
  
  
  
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          className="main-title h2-title"
        >
          <span className="mb-3">Informations de la société </span>
          <br />
          <Typography
            variant="h5"
            color="textSecondary"
            sx={{ mt: 2 }}
          >
            {updatedProject?.projectName || ''}
          </Typography>

        </Typography>
        <div className="bg-white card-box border-20">
          {/* <form className="bg-white card-box border-20" onSubmit={handleSubmit}> */}

          <div className="row">
            {partners.some((partner) => partner.isMainLeader) ? (
              partners
                .filter((partner) => partner.isMainLeader)
                .map((leader) => (
                  <React.Fragment key={leader.partnerId}>
                    {/* Main Leader Section */}
                    <div className="col-md-6">
                    <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                      <AccordionSummary >
                        <h4>
                          <ExpandMoreIcon style={{ marginRight: '8px' }} /> Fiche dirigeant
                        </h4>
                    </AccordionSummary>

                        <AccordionDetails>
                          {[
                            { label: 'Prénom', id: 'firstNames', placeholder: 'Prénom' },
                            { label: 'Nom', id: 'name', placeholder: 'Nom' },
                            { label: 'Lieu de naissance', id: 'placeOfBirthday', placeholder: 'Lieu de naissance' },
                            { label: 'Date de naissance', id: 'dateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                            { label: 'Situation professionnelle', id: 'professionalSituation', placeholder: 'Situation professionnelle' },
                            { label: 'Numéro de sécurité sociale', id: 'socialSecurityNumber', placeholder: 'Numéro de sécurité sociale' },
                            { label: 'Nationalité', id: 'nationality', placeholder: 'Nationalité' },
                          ].map((field) => (
                            <div key={field.id} className="mb-4">
                              <TextField
                                label={field.label}
                                fullWidth
                                variant="outlined"
                                value={leader[field.id] || ''} 
                                placeholder={field.placeholder}
                                onChange={(e) => handlePartnerChange(e, leader.partnerId)} 
                                id={field.id}
                                type={field.type || 'text'}
                                InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                              />
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    {/* Spouse Section */}
                    <div className="col-md-6">
                    <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                      <AccordionSummary>
                        <h4><ExpandMoreIcon style={{ marginRight: '8px' }} /> Conjoint(e)</h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        {[
                          { label: 'Prénom', value: leader.spouseIdentity?.firstNames || '', id: 'spouseFirstNames', placeholder: 'Prénom' },
                          { label: 'Nom', value: leader.spouseIdentity?.name || '', id: 'spouseName', placeholder: 'Nom' },
                          { label: 'Nom de jeune fille', value: leader.spouseIdentity?.maidenName || '', id: 'spouseMaidenName', placeholder: 'Nom de jeune fille' },
                          { label: 'Lieu de naissance', value: leader.spouseIdentity?.placeOfBirthday || '', id: 'spousePlaceOfBirthday', placeholder: 'Lieu de naissance' },
                          { label: 'Date de naissance', value: leader.spouseIdentity?.dateOfBirthday || '', id: 'spouseDateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                          { label: 'Nationalité', value: leader.spouseIdentity?.nationality || '', id: 'spouseNationality', placeholder: 'Nationalité' },
                        ].map((field) => (
                          <div key={field.id} className="mb-4">
                            <TextField
                              label={field.label}
                              fullWidth
                              variant="outlined"
                              value={field.value} 
                              placeholder={field.placeholder}
                              onChange={(e) => handlePartnerChange(e, leader.partnerId)} 
                              id={field.id}
                              type={field.type || 'text'}
                              InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                            />
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  </React.Fragment>
                ))
            ) : (
              <React.Fragment>
                {/* Create Main Leader */}
                <div className="col-md-6">
                  <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary>
                      <h4><ExpandMoreIcon style={{ marginRight: '8px' }} /> Dirigeant</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      {[
                        { label: 'Prénom', value: '', id: 'firstNames', placeholder: 'Prénom' },
                        { label: 'Nom', value: '', id: 'name', placeholder: 'Nom' },
                        { label: 'Lieu de naissance', value: '', id: 'placeOfBirthday', placeholder: 'Lieu de naissance' },
                        { label: 'Date de naissance', value: '', id: 'dateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                        { label: 'Situation professionnelle', value: '', id: 'professionalSituation', placeholder: 'Situation professionnelle' },
                        { label: 'Numéro de sécurité sociale', value: '', id: 'socialSecurityNumber', placeholder: 'Numéro de sécurité sociale' },
                        { label: 'Nationalité', value: '', id: 'nationality', placeholder: 'Nationalité' },
                      ].map((field) => (
                        <div key={field.id} className="mb-4">
                          <TextField
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            value={partners.find((p) => p.partnerId === 'newPartner')?.[field.id] || ''} 
                            placeholder={field.placeholder}
                            onChange={handleNewPartnerChange} 
                            id={field.id}
                            type={field.type || 'text'}
                            InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                          />
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>

                {/* Create Spouse */}
                <div className="col-md-6">
                  <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary>
                      <h4><ExpandMoreIcon style={{ marginRight: '8px' }} /> Conjoint(e)</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      {[
                        { label: 'Prénom', value: '', id: 'spouseFirstNames', placeholder: 'Prénom' },
                        { label: 'Nom', value: '', id: 'spouseName', placeholder: 'Nom' },
                        { label: 'Nom de jeune fille', value: '', id: 'spouseMaidenName', placeholder: 'Nom de jeune fille' },
                        { label: 'Lieu de naissance', value: '', id: 'spousePlaceOfBirthday', placeholder: 'Lieu de naissance' },
                        { label: 'Date de naissance', value: '', id: 'spouseDateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                        { label: 'Nationalité', value: '', id: 'spouseNationality', placeholder: 'Nationalité' },
                      ].map((field) => (
                        <div key={field.id} className="mb-4">
                          <TextField
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            value={partners.find((p) => p.partnerId === 'newPartner')?.[field.id] || ''} 
                            placeholder={field.placeholder}
                            onChange={handleNewPartnerChange} 
                            id={field.id}
                            type={field.type || 'text'}
                            InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                          />
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </React.Fragment>
            )}
          </div>

          <hr className="mb-30 mt-30" />
             <div className="col-md-12">
                  <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                      <AccordionSummary >
                            <h4>
                              <ExpandMoreIcon style={{ marginRight: '8px' }} /> Document à fournir
                            </h4>
                        </AccordionSummary>
            
                        <div className="table-responsive mt-20 mb-20" id="liste-table">
                          <table className="table job-alert-table">
                            <tbody>
                              <tr>
                                <td className="text-left p-2">nom pièce 1</td>
                                <td className="text-center p-2">
                                  <i
                                    className="fas fa-file-alt text-success fs-4"
                                    onClick={() => handleIconClick1("text-success")}
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                </td>
                                <td className="text-center p-2">En attente/validé/rejeté</td>
                                <td className="text-center p-2">message du cabinet comptable</td>
                                <td className="text-center p-2">
                                  <button onClick={() => setShowModal(true)}>Charger</button>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-left p-2">nom pièce 2</td>
                                <td className="text-center p-2">
                                  <i
                                    className="fas fa-file-alt text-danger fs-4"
                                    onClick={() => handleIconClick("text-danger")}
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                </td>
                                <td className="text-center p-2">En attente/validé/rejeté</td>
                                <td className="text-center p-2">message du cabinet comptable</td>
                                <td className="text-center p-2">
                                  <button onClick={() => setShowModal(true)}>Charger</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                              <Modal.Title>Upload un nouveau fichier</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <input type="file" onChange={handleFileChange} />
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Annuler
                              </Button>
                              <Button variant="primary" onClick={handleUpload}>
                                Charger
                              </Button>
                            </Modal.Footer>
                          </Modal>

                          {/* Drawer */}




                        </div>
                        </Accordion>
                        </div>
         

          <hr className="mb-30 mt-30" />
          <div className="row">
            <div className="col-md-12">
              <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                <AccordionSummary style={{ cursor: 'pointer' }}>
                  <h4> <ExpandMoreIcon style={{ marginRight: '8px' }} />Fiche entreprise</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <TextField
                        label="Nom de l'entreprise"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: !!company.name,
                        }}
                        id="name"  
                        value={company.name}
                        onChange={handleCompanyChange} 
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        label="Adresse"
                        fullWidth
                        variant="outlined"
                        id="address"  
                        InputLabelProps={{
                          shrink: !!company.address,
                        }}
                        value={company.address}
                        onChange={handleCompanyChange} 
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <TextField
                        label="Numéro de téléphone"
                        fullWidth
                        variant="outlined"
                        id="phone" 
                        InputLabelProps={{
                          shrink: !!company.phone,
                        }}
                        value={company.phone}
                        onChange={handleCompanyChange} 
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        label="Email"
                        fullWidth
                        variant="outlined"
                        id="email"  
                        InputLabelProps={{
                          shrink: !!company.email,
                        }}
                        value={company.email}
                        onChange={handleCompanyChange} 
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <TextField
                        // label="Montant du capital social"
                        fullWidth
                        variant="outlined"
                        id="socialCapitalAmount"
                        InputLabelProps={{
                          shrink: !!company.socialCapitalAmount,
                        }}
                        value={company.socialCapitalAmount}
                        onChange={handleCompanyChange} 
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        label="Devise du capital social"
                        fullWidth
                        variant="outlined"
                        id="socialCapitalCurrency" 
                        InputLabelProps={{
                          shrink: !!company.socialCapitalCurrency,
                        }}
                        value={company.socialCapitalCurrency}
                        onChange={handleCompanyChange}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <TextField
                        // label="Montant des actions"
                        fullWidth
                        variant="outlined"
                        id="capitalStockAmount"  
                        InputLabelProps={{
                          shrink: !!company.capitalStockAmount,
                        }}
                        value={company.capitalStockAmount}
                        onChange={handleCompanyChange} 
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        label="Devise des actions"
                        fullWidth
                        variant="outlined"
                        id="capitalStockCurrency" 
                        InputLabelProps={{
                          shrink: !!company.capitalStockCurrency,
                        }}
                        value={company.capitalStockCurrency}
                        onChange={handleCompanyChange} 
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

          </div>
          <hr className="mb-30 mt-30" />
          <div className="row">
          <div style={{ cursor: "pointer" }} onClick={toggleAccordionGeneral}>
              <h4>
                <ExpandMoreIcon style={{ marginRight: "8px" }} />
                Fiche associés
              </h4>
            </div>
            {isOpenGeneral && (
              <>
             {partners.some((partner) => !partner.isMainLeader) ? (
              partners
                .filter((partner) => !partner.isMainLeader)
                .map((leader) => (
                  <React.Fragment key={leader.partnerId}>
                    {/* Main Leader Section */}
                    <div className="col-md-6">
                      <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                        <AccordionSummary>
                          <h4>Associé1</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                          {[
                            { label: 'Prénom', id: 'firstNames', placeholder: 'Prénom' },
                            { label: 'Nom', id: 'name', placeholder: 'Nom' },
                            { label: 'Lieu de naissance', id: 'placeOfBirthday', placeholder: 'Lieu de naissance' },
                            { label: 'Date de naissance', id: 'dateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                            { label: 'Situation professionnelle', id: 'professionalSituation', placeholder: 'Situation professionnelle' },
                            { label: 'Numéro de sécurité sociale', id: 'socialSecurityNumber', placeholder: 'Numéro de sécurité sociale' },
                            { label: 'Nationalité', id: 'nationality', placeholder: 'Nationalité' },
                          ].map((field) => (
                            <div key={field.id} className="mb-4">
                              <TextField
                                label={field.label}
                                fullWidth
                                variant="outlined"
                                value={leader[field.id] || ''} 
                                placeholder={field.placeholder}
                                onChange={(e) => handlePartnerChange(e, leader.partnerId)} 
                                id={field.id}
                                type={field.type || 'text'}
                                InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                              />
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    {/* Spouse Section */}
                    <div className="col-md-6">
                    <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                      <AccordionSummary>
                        <h4><ExpandMoreIcon style={{ marginRight: '8px' }} />Conjoint(e)</h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        {[
                          { label: 'Prénom', value: leader.spouseIdentity?.firstNames || '', id: 'spouseFirstNames', placeholder: 'Prénom' },
                          { label: 'Nom', value: leader.spouseIdentity?.name || '', id: 'spouseName', placeholder: 'Nom' },
                          { label: 'Nom de jeune fille', value: leader.spouseIdentity?.maidenName || '', id: 'spouseMaidenName', placeholder: 'Nom de jeune fille' },
                          { label: 'Lieu de naissance', value: leader.spouseIdentity?.placeOfBirthday || '', id: 'spousePlaceOfBirthday', placeholder: 'Lieu de naissance' },
                          { label: 'Date de naissance', value: leader.spouseIdentity?.dateOfBirthday || '', id: 'spouseDateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                          { label: 'Nationalité', value: leader.spouseIdentity?.nationality || '', id: 'spouseNationality', placeholder: 'Nationalité' },
                        ].map((field) => (
                          <div key={field.id} className="mb-4">
                            <TextField
                              label={field.label}
                              fullWidth
                              variant="outlined"
                              value={field.value} 
                              placeholder={field.placeholder}
                              onChange={(e) => handlePartnerChange(e, leader.partnerId)} 
                              id={field.id}
                              type={field.type || 'text'}
                              InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                            />
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  </React.Fragment>
                ))
            ) : (
              <React.Fragment>
                {/* Create Main Leader */}
                <div className="col-md-6">
                  <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary>
                      <h4><ExpandMoreIcon style={{ marginRight: '8px' }} />Associé1</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      {[
                        { label: 'Prénom', value: '', id: 'firstNames', placeholder: 'Prénom' },
                        { label: 'Nom', value: '', id: 'name', placeholder: 'Nom' },
                        { label: 'Lieu de naissance', value: '', id: 'placeOfBirthday', placeholder: 'Lieu de naissance' },
                        { label: 'Date de naissance', value: '', id: 'dateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                        { label: 'Situation professionnelle', value: '', id: 'professionalSituation', placeholder: 'Situation professionnelle' },
                        { label: 'Numéro de sécurité sociale', value: '', id: 'socialSecurityNumber', placeholder: 'Numéro de sécurité sociale' },
                        { label: 'Nationalité', value: '', id: 'nationality', placeholder: 'Nationalité' },
                      ].map((field) => (
                        <div key={field.id} className="mb-4">
                          <TextField
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            value={partners.find((p) => p.partnerId === 'newPartner')?.[field.id] || ''} 
                            placeholder={field.placeholder}
                            onChange={handleNewPartnerChange} 
                            id={field.id}
                            type={field.type || 'text'}
                            InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                          />
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>

                {/* Create Spouse */}
                <div className="col-md-6">
                  <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary>
                      <h4><ExpandMoreIcon style={{ marginRight: '8px' }} />Conjoint(e)</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      {[
                        { label: 'Prénom', value: '', id: 'spouseFirstNames', placeholder: 'Prénom' },
                        { label: 'Nom', value: '', id: 'spouseName', placeholder: 'Nom' },
                        { label: 'Nom de jeune fille', value: '', id: 'spouseMaidenName', placeholder: 'Nom de jeune fille' },
                        { label: 'Lieu de naissance', value: '', id: 'spousePlaceOfBirthday', placeholder: 'Lieu de naissance' },
                        { label: 'Date de naissance', value: '', id: 'spouseDateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                        { label: 'Nationalité', value: '', id: 'spouseNationality', placeholder: 'Nationalité' },
                      ].map((field) => (
                        <div key={field.id} className="mb-4">
                          <TextField
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            value={partners.find((p) => p.partnerId === 'newPartner')?.[field.id] || ''} 
                            placeholder={field.placeholder}
                            onChange={handleNewPartnerChange} 
                            id={field.id}
                            type={field.type || 'text'}
                            InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                          />
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </React.Fragment>
            )}

              <div className="table-responsive mt-20 mb-20" id="liste-table">
                <table className="table job-alert-table">
                  <tbody>
                    <tr>
                      <td className="text-left p-2">nom pièce 1</td>
                      <td className="text-center p-2">
                        <i
                          className="fas fa-file-alt text-success fs-4"
                          onClick={() => handleIconClick1('text-success')}
                        ></i>
                      </td>
                      <td className="text-center p-2">En attente/validé/rejeté</td>
                      <td className="text-center p-2">message du cabinet comptable</td>
                      <td className="text-center p-2">
                        <button onClick={() => setShowModal1(true)}>Charger</button>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left p-2">nom pièce 2</td>
                      <td className="text-center p-2">
                        <i className="fas fa-file-alt text-danger fs-4"></i>
                      </td>
                      <td className="text-center p-2">En attente/validé/rejeté</td>
                      <td className="text-center p-2">message du cabinet comptable</td>
                      <td className="text-center p-2">
                        <button onClick={() => setShowModal1(true)}>Charger</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="d-flex justify-content-start mt-4 mb-3">
                <button className="dash-btn-two tran3s me-3" onClick={handleOpenAddAssocie}>
                  Ajouter un associé
                </button>
              </div>

              <AddAssocie
                show={showModalAddAssocie}
                handleClose={() => setShowModalAddAssocie(false)}
              />
           </>
            )}
          </div>


          <div className="d-flex justify-content-center mt-4">
            <button className="dash-btn-two tran3s me-3" type="button" onClick={handleSubmit}>
              Mettre à jour
            </button>
          </div>
          <Modal show={showModal1} onHide={() => setShowModal1(false)}>
            <Modal.Header closeButton>
              <Modal.Title> Charger un nouveau fichier </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input type="file" onChange={handleFileChange} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal1(false)}>
                Annuler
              </Button>
              <Button variant="primary" onClick={handleUpload}>
                Charger
              </Button>
            </Modal.Footer>
          </Modal>
          {/* </form> */}
        </div>

        <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
          <div style={{ width: 300, padding: 20 }}>
            <button onClick={closeDrawer}>Fermer</button>
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <img
                src="/assets/images/logo/logo-pharos.png"
                alt="Document"
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
          </div>
        </Drawer>
      </div>
     
    </div>
  );
};

export default InformationsSociete;