import axiosInstance from './axiosConfig';

export const fetchCreateProject = async ( projectName) => {
  try {
    const response = await axiosInstance.post('/graphql', { 
      query: `
        mutation initAccountancyProjectConfig($projectId: String, $projectName: String!) {
          initAccountancyProjectConfig(projectId: $projectId, projectName: $projectName)
        }
      `,
      variables: {
        projectName,
      },
    });

    console.log(response.data); 
    return response.data;
  } catch (error) {
    console.error("Error occurred during project creation:", error);
    throw error; 
  }
};
export const fetchListProjects = async () => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
          query getAccountancyProjectConfigs($limit: Int, $nextToken: String) {
            getAccountancyProjectConfigs(limit: $limit, nextToken: $nextToken) {
              items {
                projectId
                projectName
              }
              nextToken
            }
          }
        `,
        variables: {},
      });
  
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error occurred during project retrieval:", error);
      throw error;
    }
  };
  