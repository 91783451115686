import React, { useState,useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'; 
import { getWebpackageOrder, upsertWebpackageOrder } from "../../../api/webPackage";
import Swal from "sweetalert2";

const CandidateV3Area = () => {
  const { projectId } = useParams();
  const navigate=useNavigate();
  const [project,setProject]=useState();
  const dispatch = useDispatch();

  const {
    domainName,
    mailBoxEmailAddresses,
    address,
    phone,
    hours,
    socialNetworks,
    services,
    fontFamily,
    backgroundColor,
    textColor,
    icon,
    colorForeground,
    colorBackground,
    title,
    slogan,
    fontTitle,
    fontSlogan,
    s3ImageKey,
    imgMagasin

  } = useSelector((state) => state.package);  

  const siteModelImage = "/assets/images/blog/njt.png";

  useEffect (() => {
    const fetchProjectData = async () => {
      try {
        const response = await getWebpackageOrder(projectId);
        console.log("GraphQL response:", response);
        const projectData = response.data.getWebpackageOrder; 
        setProject(projectData);
        console.log("dfrgrt", projectData.projectName);
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };
    

    fetchProjectData();
  }, [projectId, dispatch]);


  const handleSaveChanges = async () => {
    try {

      const formatHours = (hours) => {
        const formattedHours = {};
      
        Object.entries(hours).forEach(([day, values]) => {
          if (values.every(val => val === "")) {
            formattedHours[day] = [];
          } else {
            const formattedValues = [];
            
            if (values[0] && values[1]) {
              formattedValues.push(`${values[0]}-${values[1]}`);
            }
              if (values[2] && values[3]) {
              formattedValues.push(`${values[2]}-${values[3]}`);
            }
      
            formattedHours[day] = formattedValues;
          }
        });
      
        console.log("formattedHours", formattedHours);
        return formattedHours;
      };
      
      const formattedHours = formatHours(hours);

      const input = {
        input: {
          projectName: project.projectName,  
          projectId:projectId,
          logo: {
            logoType: "TO_CREATE",  
            logoToCreate: {
              icon :icon ||"",
              s3ImageKey: s3ImageKey,
              fontTitle: fontTitle|| "",
              fontSlogan: fontSlogan ||"",
              colorForeground : colorForeground ||"",
              colorBackground:colorBackground||"",
              title:title||"",
              slogan:slogan||"",
            },
          },
          domainName:domainName,
          mailBoxEmailAddresses:mailBoxEmailAddresses,
          website: {
            globalDetails: {
              companyName: "test",
              language: "FR",
              backgroundColor:backgroundColor,
              textColor:textColor,
              fontFamily:fontFamily,
              address:address,
              phoneNumber: phone,
              email: `contact@${domainName}`,
              socialNetworks :socialNetworks,
              days: formattedHours, 
            },
            navbar: {
              backgroundColor:"cyan",
              textColor:"cyan",
              items: ["about", "gallery", "services", "contact"],
            },
            header: {
              title :"title",
              subtitle: "Réaliser",
              s3Key: s3ImageKey,  
            },
            services: {
              text: services.text,
              items: services.items,
            },
            gallery: [
              {
                s3Key: s3ImageKey,  
                title: "image 1",
              },
            ],
            about: {
              paragraph: "Some description text",
              why: ["Reason 1", "Reason 2"],
              s3Key:imgMagasin,
            },
            footer: {
              backgroundColor: "cyan",
              textColor: "cyan",
            },
          },
        },
      };
     console.log("input",input);
      const response = await upsertWebpackageOrder(input);
      Swal.fire({
        icon: "success",
        title: "Project webpackage ",
        text: ` a été modifier avec succès.`,
      });
    navigate(`/info-projet/${projectId}`)
      console.log("Response from API:", response);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '135px', paddingBottom: '30px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION PACKAGE COMMERCIAL</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to={`/package-com11/${projectId}`}>
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">15/15</span>
            </div>
          </div>
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h4 className="fw-600">Visualisez votre site vitrine</h4>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <div className="card mb-4">
                <img src={siteModelImage} className="card-img-top" alt="Modèle du site" />
                <div className="card-body">
                  <h5 className="card-title text-center">Modèle du site</h5>
                </div>
              </div>
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="btn-eleven fw-500 tran3s d-block mt-20"
                onClick={handleSaveChanges}
              >
                Enregistrez les modifications
              </button>
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to={`/package-com1/${projectId}`} className="d-block">
                  Recommencez
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
