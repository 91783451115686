import React, { useEffect, useState } from 'react';
import JobAlertItem from './mes-demarche-item';
import DashboardHeader from '../../header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Pagination,
  Stack,
} from '@mui/material';
import { styled } from '@mui/system';
import { fetchGetCompany } from '../../../../../api/companyService';

const CustomPagination = styled(Pagination)({
  '& .MuiPaginationItem-root': {
    color: '#000',
  },
  '& .Mui-selected': {
    backgroundColor: 'rgb(25, 175, 120)!important',
    color: '#fff',
  },
  '& .MuiPaginationItem-ellipsis': {
    color: '#000',
  },
});

const StyledTableContainer = styled(TableContainer)({
  backgroundColor: '#fff',
  borderRadius: '20px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
});

const StyledTableCell = styled(TableCell)({
  textAlign: 'center',
  fontWeight: 'bold',
  color: '#333',
  verticalAlign: 'middle',
});

const StyledButton = styled(Button)({
  backgroundColor: 'rgb(25, 175, 120)',
  color: '#fff',
  borderRadius: '20px',
  padding: '8px 16px',
  textTransform: 'none',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: 'rgb(25, 175, 120)',
    color: '#fff',
  },
});


const MesDemarches = ({ setIsOpenSidebar }) => {
   
  const location = useLocation();
  const { project } = location.state || {}; 

  console.log('Project data:', project);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await fetchGetCompany();
        const projectItems = data?.data?.getCompanyOrders?.items || [];
        setProjects(projectItems);
      } catch (error) {
        console.error('Error fetching company projects:', error);
      }
    };

    fetchProjects();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProjects = projects.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleProjectClick = (project) => {
    if (project) {
      navigate('/mes-demarches-create', { state: { project } });
    } else {
      console.error('Project details not found.');
    }
  };
  

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <Typography variant="h4" align="center" gutterBottom className="main-title h2-title">
          Mes démarches
        </Typography>

        <StyledTableContainer component={Paper}>
          <Table className="job-alert-table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Projet</StyledTableCell>
                <StyledTableCell>Statut</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentProjects.map((project, index) => (
                <JobAlertItem
                  key={project.projectId || index}
                  title={project.projectName}
                  // location={project.companyDetail?.address || 'Adresse non définie'}
                  category={project.projectStatus || 'Statut non défini'}
                  duration="Durée à compléter"
                  found="2"
                  time="Weekly"
                  onClick={() => handleProjectClick(project)}
                />
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>

        <Stack direction="row" justifyContent="center" mt={4}>
          <StyledButton component={Link} to="/statut-juridique">
            Créer une nouvelle société
          </StyledButton>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" mt={4}>
          <CustomPagination
            count={Math.ceil(projects.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            shape="rounded"
          />
        </Stack>
      </div>
    </div>
  );
};

export default MesDemarches;
