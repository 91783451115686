import React, { useState } from "react";
import { Link } from "react-router-dom";
// import CandidateFilterModal from "../common/popup/candidate-filter-modal";

// Import your icon components or use icon libraries
import { FaCalendarDay, FaCommentDots, FaQuestionCircle, FaCheckCircle } from 'react-icons/fa';

const CandidateV3Area = ({ style_2 }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [textareaValue, setTextareaValue] = useState("");

  const handleCheckboxChange = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
    );
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Commandez votre rendez-vous</h2>
                {/* <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">Sélectionnez les thèmes que vous souhaitez aborder avec nos experts (plusieurs choix possibles)</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="candidates-profile  pt-90 lg-pt-70 pb-160 xl-pb-150 lg-pb-80" style={{ backgroundColor: '#FFF' }}>
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to="/rendez-vous4">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">4/5</span>
              <Link to="/rendez-vous6">
                <button type="button" className="btn btn-success">
                  {"Suivant >"}
                </button>
              </Link>
            </div>
          </div>
          <div className="row mt-5">
            {/* Recapitulatif Card */}
            <div className="col-12 mb-4">
              <div className="card">
                <div className="card-header bg-success text-white text-center">
                  <h5 className="mb-0 text-white">Récapitulatif</h5>
                </div>
                <div className="card-body">
                  <ul className="list-unstyled">
                    <li className="d-flex justify-content-between py-2">
                      <span>Total commande HT</span>
                      <span>150 €</span>
                    </li>
                    <li className="d-flex justify-content-between py-2">
                      <span>TVA</span>
                      <span>30 €</span>
                    </li>
                    <li className="d-flex justify-content-between py-2">
                      <span>Total commande TTC</span>
                      <span>180 €</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Note Section */}
            <div className="col-12 mt-4">
              <div className="alert alert-info text-center" role="alert">
                En fonction du régime TVA que vous aurez choisi, vous pourrez récupérer les frais de TVA via votre future entreprise.
              </div>
            </div>

            {/* Button Section */}
            <div className="col-12 text-center mt-4">
              <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/rendez-vous6" className="text-white">
                  Payer → 
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* <CandidateFilterModal /> */}
    </>
  );
};

export default CandidateV3Area;
