import React from 'react';
import DashboardHeader from '../../header';

const ClientsFournisseursIndex = ({setIsOpenSidebar}) => {
  const handleHistoriqueClick = () => {    
    const historiqueTable = document.getElementById('historique-table');
    if (historiqueTable) {
      historiqueTable.style.display = 'block';
    }    
    const listeTable = document.getElementById('liste-table');
    if (listeTable) {
      listeTable.style.display = 'none';
    }    
    const historiqueBtn = document.getElementById('historique-btn');
    if (historiqueBtn) {
      historiqueBtn.style.display = 'none';
    }    
    const listeBtn = document.getElementById('liste-btn');
    if (listeBtn) {
      listeBtn.style.display = 'block';
    }    
  };
  const handleListeClick = () => {    
    const historiqueTable = document.getElementById('historique-table');
    if (historiqueTable) {
      historiqueTable.style.display = 'none';
    }    
    const listeTable = document.getElementById('liste-table');
    if (listeTable) {
      listeTable.style.display = 'block';
    }    
    const historiqueBtn = document.getElementById('historique-btn');
    if (historiqueBtn) {
      historiqueBtn.style.display = 'block';
    }    
    const listeBtn = document.getElementById('liste-btn');
    if (listeBtn) {
      listeBtn.style.display = 'none';
    }    
  };
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className="main-title h2-titel">Mes clients / fournisseurs</h2>
        <div className="bg-white card-box border-20"> 
          <div className="d-flex justify-content-between mb-4"> 
            <a href="#" className="dash-btn-two tran3s me-3" id='historique-btn' onClick={handleHistoriqueClick}>Historique</a>   
            <a href="#" className="dash-btn-two tran3s me-3" id='liste-btn' style={{ display: 'none' }} onClick={handleListeClick}>Liste clients / fournisseurs</a>
            <a href="#" className="dash-btn-two tran3s me-3" >Créer clients / fournisseurs</a>                                                                                 
          </div>                                

          <div className="table-responsive" id="liste-table">
            <h4>Liste des clients / fournisseurs</h4>
            <table className="table job-alert-table">
              <thead>
                <tr>
                  <th scope="col" className='text-center'>clients / fournisseurs</th>                                                      
                  <th scope="col" className='text-center'>Actions</th>                                  
                </tr>
              </thead>
              <tbody className="border-0">
                <tr>                                
                  <td className='text-center'>nom client</td>                                                  
                  <td className='text-center'>
                    <div className="action-dots float-end">
                      <button
                        className="action-btn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span></span>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <a className="dropdown-item" href="#">
                            <img src="/assets/dashboard/images/icon/icon_18.svg" alt="icon" className="lazy-img" /> Voir
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <img src="/assets/dashboard/images/icon/icon_19.svg" alt="icon" className="lazy-img" /> historique
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <img src="/assets/dashboard/images/icon/icon_20.svg" alt="icon" className="lazy-img" /> Modifier
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <img src="/assets/dashboard/images/icon/icon_21.svg" alt="icon" className="lazy-img" />{" "}
                            Supprimer
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>                                                  
                </tr>            
                <tr>                              
                  <td className='text-center'>nom client</td>                                                  
                  <td className='text-center'>
                    <div className="action-dots float-end">
                      <button
                        className="action-btn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span></span>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <a className="dropdown-item" href="#">
                            <img src="/assets/dashboard/images/icon/icon_18.svg" alt="icon" className="lazy-img" /> Voir
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <img src="/assets/dashboard/images/icon/icon_19.svg" alt="icon" className="lazy-img" /> historique
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <img src="/assets/dashboard/images/icon/icon_20.svg" alt="icon" className="lazy-img" /> Modifier
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <img src="/assets/dashboard/images/icon/icon_21.svg" alt="icon" className="lazy-img" />{" "}
                            Supprimer
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>                                
                </tr>            
                <tr>                                  
                  <td className='text-center'>nom client</td>                                                   
                  <td className='text-center'>
                    <div className="action-dots float-end">
                      <button
                        className="action-btn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span></span>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <a className="dropdown-item" href="#">
                            <img src="/assets/dashboard/images/icon/icon_18.svg" alt="icon" className="lazy-img" /> Voir
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <img src="/assets/dashboard/images/icon/icon_19.svg" alt="icon" className="lazy-img" /> historique
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <img src="/assets/dashboard/images/icon/icon_20.svg" alt="icon" className="lazy-img" /> Modifier
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <img src="/assets/dashboard/images/icon/icon_21.svg" alt="icon" className="lazy-img" />{" "}
                            Supprimer
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>                             
                </tr>            
              </tbody>
            </table>
          </div>  

          <div className="table-responsive" id="historique-table" style={{ display: 'none' }}>
            <h4>Historique des clients / fournisseurs</h4>
            <table className="table job-alert-table">
              <thead>
                <tr>
                  <th scope="col" className='text-center'>clients / fournisseurs</th>                                  
                </tr>
              </thead>
              <tbody className="border-0">
                <tr>                               
                  <td className='text-center'>nom client</td>                                                                            
                </tr>                                        
              </tbody>
            </table>
          </div>

        </div>                                          
      </div>
    </div>
  );
};

export default ClientsFournisseursIndex;