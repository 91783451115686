import React, { useState } from "react";
import {
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { addContact } from "../../../../api/emailService";

const AddContactModal = ({ open, handleClose, handleAddContact, adressEmail }) => {
  const [newContact, setNewContact] = useState({
    givenName: "",
    surname: "",
    companyName: "",
    phoneNumbers: [{ phoneNumber: "" }],
    emailAddresses: [{ email: "" }],
    physicalAddresses: [
      { street: "", city: "", country: "", zipcode: "" },
    ],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewContact((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleArrayChange = (e, index, field, arrayName) => {
    const { value } = e.target;
    setNewContact((prev) => {
      const updatedArray = [...prev[arrayName]];
      updatedArray[index][field] = value;
      return { ...prev, [arrayName]: updatedArray };
    });
  };

  const handleSubmit = () => {
    const payload = {
      sourceEmailAddress: adressEmail, 
      input: newContact,
    };
    addContact(payload);
    
    handleClose();
   
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <header className="modal-header">
          <h3 style={{ color: "green" }}>Ajouter un nouveau contact</h3>
          <button className="close-modal" onClick={handleClose}>
            ×
          </button>
        </header>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Prénom"
              name="givenName"
              value={newContact.givenName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
            <TextField
              label="Nom"
              name="surname"
              value={newContact.surname}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
            <TextField
              label="Entreprise"
              name="companyName"
              value={newContact.companyName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
            {newContact.emailAddresses.map((email, index) => (
              <TextField
                key={index}
                label={`Email ${index + 1}`}
                value={email.email}
                onChange={(e) =>
                  handleArrayChange(e, index, "email", "emailAddresses")
                }
                variant="outlined"
                fullWidth
              />
            ))}

            {newContact.phoneNumbers.map((phone, index) => (
              <TextField
                key={index}
                label={`Téléphone ${index + 1}`}
                value={phone.phoneNumber}
                onChange={(e) =>
                  handleArrayChange(e, index, "phoneNumber", "phoneNumbers")
                }
                variant="outlined"
                fullWidth
              />
            ))}
            <TextField
              label="Rue"
              name="street"
              value={newContact.physicalAddresses[0].street}
              onChange={(e) =>
                handleArrayChange(e, 0, "street", "physicalAddresses")
              }
              variant="outlined"
              fullWidth
            />
            <TextField
              label="Ville"
              name="city"
              value={newContact.physicalAddresses[0].city}
              onChange={(e) =>
                handleArrayChange(e, 0, "city", "physicalAddresses")
              }
              variant="outlined"
              fullWidth
            />
            <TextField
              label="Pays"
              name="country"
              value={newContact.physicalAddresses[0].country}
              onChange={(e) =>
                handleArrayChange(e, 0, "country", "physicalAddresses")
              }
              variant="outlined"
              fullWidth
            />
            <TextField
              label="Code Postal"
              name="zipcode"
              value={newContact.physicalAddresses[0].zipcode}
              onChange={(e) =>
                handleArrayChange(e, 0, "zipcode", "physicalAddresses")
              }
              variant="outlined"
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Ajouter
          </Button>
        </DialogActions>
      </div>
    </div>
  );
};

export default AddContactModal;
