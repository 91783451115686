import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchImages, generateS3PreSignedUploadUrl } from "../../../api/webPackage";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setGallery } from "../../../redux/packageSlice";

const CandidateV3Area = ({ style_2 }) => {
  const [images, setImages] = useState([]);
  const gallery = useSelector((state) => state.package.gallery || []);
  const dispatch = useDispatch();
    console.log("gallery",gallery);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { projectId } = useParams();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  

  const handleImageSelect = (selectedImage) => {
    const isImageSelected = selectedImages.includes(selectedImage.s3Key);
  
    if (isImageSelected) {
      setSelectedImages((prevKeys) =>
        prevKeys.filter((key) => key !== selectedImage.s3Key)
      );
    } else {
      setSelectedImages((prevKeys) => [...prevKeys, selectedImage.s3Key]);
      const updatedGallery = [...gallery, selectedImage];
      dispatch(setGallery(updatedGallery));
    }
  };
  
  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
    setUploadError(null);
  };

  useEffect(() => {
    const loadImages = async () => {
      try {
        const response = await fetchImages(); 
        const items = response.data?.getListOfImages?.items || [];
  
        const formattedImages = response.map((url, index) => ({
          s3Key: url,
          title:`image-${index}`, 
        }));
  
        setImages(formattedImages); 
        console.log('items',response)

        console.log('formattedImages',formattedImages)
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    loadImages();
  }, []);
  
  

  const handleUpload = async () => {
    setIsUploading(true);
    setUploadError(null);
  
    if (!selectedFiles || selectedFiles.length === 0) {
      setUploadError("Aucun fichier sélectionné.");
      setIsUploading(false);
      return;
    }
  
    const filesArray = Array.from(selectedFiles);
  
    const uploadedFilesData = []; 
    try {
   
      const uploadPromises = filesArray.map(async (file) => {
        try {
          const response = await generateS3PreSignedUploadUrl(projectId, file.name);
          const { url, fields } = response;
  
          const fileData = new FormData();
          fileData.append("key", fields.key);
          fileData.append("policy", fields.policy);
          fileData.append("x-amz-algorithm", fields.xAmzAlgorithm);
          fileData.append("x-amz-credential", fields.xAmzCredential);
          fileData.append("x-amz-date", fields.xAmzDate);
          fileData.append("x-amz-security-token", fields.xAmzSecurityToken);
          fileData.append("x-amz-signature", fields.xAmzSignature);
          fileData.append("file", file);
  
          await axios.post(url, fileData);
  
          return {
            s3Key: fields.key,
            title: file.name,
          };
        } catch (uploadError) {
          console.error(`Erreur lors du téléversement du fichier ${file.name}:`, uploadError);
          throw new Error(`Erreur avec le fichier ${file.name}`);
        }
      });
  
      const uploadedFiles = await Promise.all(uploadPromises);
  
      const updatedGallery = Array.isArray(gallery)
        ? [...gallery, ...uploadedFiles]
        : uploadedFiles;
  
      dispatch(setGallery(updatedGallery));
  
      setUploadMessage("Téléversement réussi !");
    } catch (error) {
      console.error("Erreur lors du téléversement des fichiers :", error);
      setUploadError("Erreur lors du téléversement de certains fichiers.");
    } finally {
      setIsUploading(false);
    }
  };
  

  useEffect(() => {
    const loadImages = async () => {
      try {
        const fetchedImages = await fetchImages();
        setImages(fetchedImages);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadImages();
  }, []);

  
  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div
            className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
            style={{ paddingTop: "135px", paddingBottom: "30px" }}
          >
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                  CRÉATION PACKAGE COMMERCIAL
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to={`/package-com10/${projectId}`}>
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">14/15</span>
            </div>
          </div>
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h4 className="fw-600">Ajoutez des images à votre site vitrine</h4>
          </div>
          <div className="col-12 mb-4">
            <div className="input-group-meta position-relative mb-25">
              <label htmlFor="fileUpload">Chargez vos images</label>
              <input
                type="file"
                id="fileUpload"
                className="form-control"
                multiple
                onChange={handleFileChange}
              />
              <button
                type="submit"
                className="btn-eleven fw-500 tran3s d-block mt-20"
                onClick={handleUpload}
                disabled={isUploading}
              >
                Télécharger
              </button>
              {uploadError && <p className="text-danger">{uploadError}</p>}
              {uploadMessage && <p className="text-success">{uploadMessage}</p>}
            </div>
          </div>
      <div className="text-center mb-4">
        <h5 className="fw-500 mb-3">Sélectionnez des images depuis notre catalogue d’images</h5>
        {loading ? (
          <p>Chargement des images...</p>
        ) : error ? (
          <p>Erreur : {error}</p>
        ) : images.length > 0 ? (
             <div className="row">
          {images.map((image, index) => (
            <div className="col-md-3 mb-4" key={index}>
              <div
                className={`card ${selectedImages.includes(image.s3Key) ? "selected-card" : ""}`}
                style={{
                  cursor: "pointer",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  overflow: "hidden",
                  height: "200px", 
                }}
                onClick={() =>
                  handleImageSelect({
                    s3Key: image.s3Key,
                    title: image.title,
                  
                  })
                }
              >
                <img
                  src={image.s3Key}
                  className="card-img-top"
                  alt={image.title}
                  style={{ objectFit: "cover", height: "100%", width: "100%" }}
                />
              </div>
            </div>
          ))}
        </div>

        
        ) : (
          <p>Aucune image disponible dans le catalogue.</p>
        )}
      </div>
      <div className="row mt-5">
            <div className="col-12">
              <button
                type="submit"
                className="btn-eleven fw-500 tran3s d-block mt-20"
              >
                <Link to={`/package-com12/${projectId}`} className="d-block">
                  Validez
                </Link>
              </button>
            </div>
          </div>
        </div>
        <style></style>
      </section>
    </>
  );
};


export default CandidateV3Area;
