import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams,Link } from 'react-router-dom'; 
import DashboardHeader from '../header';
import ComposeEmailModal from './ComposeEmailModal';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getNumberOfMails, getReceivedMails,getDraftMails, getSentMails, getAllContact, geAttachements, deleteEmail,mutationSentEmail } from '../../../../api/emailService';
import Swal from 'sweetalert2';
import AddContactModal from './addContact';
const DashboardMessage = ({ setIsOpenSidebar }) => {
  const { emailAddress } = useParams();

  const [filter, setFilter] = useState("received"); 
  const [emails, setEmails] = useState([]);
  const [receivedEmails, setReceivedEmails] = useState([]);
  const [sentEmails, setSentEmails] = useState([]);
  const [draftEmails, setDraftEmails] = useState([]);
  const [contactEmails, setContactEmails] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);  
  const [allEmail, setAllEmail] = useState(0);
  const [unReadEmail, setUnReadEmail] = useState(0);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [isComposeModalOpen, setComposeModalOpen] = useState(false);


  const openComposeModal = () => {
    setComposeModalOpen(true);
  };

  const closeComposeModal = () => {
    setComposeModalOpen(false);
  };

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const numberResponse = await getNumberOfMails(emailAddress);
        setAllEmail(numberResponse?.totalEmailsCount + numberResponse?.unreadEmailsCount || 0);
        setUnReadEmail(numberResponse?.unreadEmailsCount || 0);
        
        const [received, sent, draft,contact] = await Promise.all([
          getReceivedMails(emailAddress),
          getSentMails(emailAddress),
          getDraftMails(emailAddress),
          getAllContact(emailAddress)
        ]);
         
        setReceivedEmails(received.items || []);
        setSentEmails(sent.items || []);
        setDraftEmails(draft.items || []);
        setContactEmails(contact.items || []);
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };
  
    if (emailAddress) {
      fetchEmails();
    }
  }, [emailAddress]);

  useEffect(() => {
    
      if (filter === "received") {
        setEmails(receivedEmails.map(email => ({ ...email, isSelected: false })));
    } else if (filter === "sent") {
      setEmails(sentEmails.map(email => ({ ...email, isSelected: false })));
    } else if (filter === "draft") {
      setEmails(draftEmails.map(email => ({ ...email, isSelected: false })));
    }
    else if (filter === "contact") {
      setFilteredEmails(contactEmails || []);
    }
  }, [filter, receivedEmails, sentEmails, draftEmails,contactEmails]);
  
  const handleRowClick = (email) => {
    setSelectedEmail(email); 
  };
  const handleSelectEmail = (email) => {
    setEmails((prevEmails) =>
      prevEmails.map((e) =>
        e.itemId === email.itemId ? { ...e, isSelected: !e.isSelected } : e
      )
    );
  };
  
  const handleSelectAll = (e) => {
    const isSelected = e.target.checked;
    setEmails((prevEmails) =>
      prevEmails.map((email) => ({ ...email, isSelected }))
    );
  };
  const handleDeleteSelected = async () => {
    const selectedEmails = emails.filter(email => email.isSelected);
    
    if (selectedEmails.length > 0) {
      const emailIds = selectedEmails.map(email => email.itemId);
  
      const result = await Swal.fire({
        icon: "warning",
        title: "Suppression des emails",
        text: `Vous voulez vraiment supprimer ${selectedEmails.length} email(s) ?`,
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: "Annuler",
      });
  
      if (result.isConfirmed) {
        try {
          const response = await deleteEmail(emailAddress, emailIds);
  
          if (response.success) {
            setEmails((emails) => emails.filter(email => !emailIds.includes(email.itemId)));
  
            Swal.fire({
              icon: "success",
              title: "Supprimé",
              text: `${selectedEmails.length} email(s) ont été supprimé(s) avec succès.`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Erreur",
              text: "Les emails n'ont pas été supprimés. Veuillez réessayer plus tard.",
            });
          }
        } catch (error) {
          console.error("Erreur lors de la suppression :", error);
          Swal.fire({
            icon: "error",
            title: "Erreur",
            text: "Une erreur est survenue lors de la suppression des emails.",
          });
        }
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "Aucune sélection",
        text: "Veuillez sélectionner au moins un email.",
      });
    }
  };
    
  /* partie ontat add*/


  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {setIsModalOpen(true);}

  const handleCloseModal = () =>{ setIsModalOpen(false);
    console.log('deer');
  }
 

  const handleAddContact = (newContact) => {
    setContactEmails((prevContacts) => [...prevContacts, { ...newContact, itemId: Date.now() }]);
  };
  const handleDelete = (itemId) => {
    const updatedContacts = contactEmails.filter(contact => contact.itemId !== itemId);
    setContactEmails(updatedContacts);
  };
  

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <div className="bg-white card-box border-20 p0 mt-30">
          <div className="message-wrapper">
            <div className="row gx-0">
              <div className="col-lg-3">
                <div className="message-sidebar ps-3 pe-3 ps-xxl-4 pe-xxl-4 mt-4">
              
                  <div className="email_interface">
                    <button className="compose_button" onClick={openComposeModal}>
                      <i className="fas fa-pencil-alt"></i> Rédiger un email
                    </button>
                        {isComposeModalOpen && (
                    <ComposeEmailModal onClose={closeComposeModal}addressEmail={emailAddress} />
                  )}
                    <div className="message_container">
                      <div className="message_filter" id="module_btns">
                        <div className="filter_item" onClick={() => setFilter("received")}>
                          <i className="fas fa-envelope"></i>
                          <span className="filter_text">Boite de réception</span>
                          <span className="filter_count">{receivedEmails?.length || 0}</span>
                        </div>
                        <div className="filter_item" onClick={() => setFilter("sent")}>
                          <i className="fas fa-paper-plane"></i>
                          <span className="filter_text">Messages envoyés</span>
                          <span className="filter_count">{sentEmails?.length || 0}</span>
                        </div>
                        <div className="filter_item" onClick={() => setFilter("draft")}>
                          <i className="fas fa-pencil-alt"></i>
                          <span className="filter_text">Brouillons</span>
                          <span className="filter_count">{draftEmails?.length || 0}</span>
                        </div>
                        <div className="filter_item" onClick={() => setFilter("contact")}>
                          <i className="fa-solid fa-user-alt"></i>
                          <span className="filter_text">Contacts</span>
                          <span className="filter_count">{contactEmails?.length || 0}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-9">
                <div className="message-sidebar ">
                <div className="ps-3 pe-3 ps-xxl-4 pe-xxl-4">
                    
                    <div className="search-form mt-20 mb-20">
                      <input type="text" placeholder="Search contacts" />
                      <button>
                        <img src="/assets/dashboard/images/icon/icon_10.svg" alt="icon" className="lazy-img m-auto" />
                      </button>
                    </div>
                            {emails.some(email => email.isSelected) && (
                          <button className="delete-selected-btn" onClick={handleDeleteSelected}>
                            Supprimer les emails sélectionnés
                          </button>
                        )}

                  </div>
                  {filter === "contact" ? (
                  <>
                    <div className="d-flex justify-content-end ps-3 pe-3 ps-xxl-4 pe-xxl-4 mt-3 mb-2">
                      <Link
                        className="dash-btn-two tran3s mt-3"
                        style={{
                          display: "inline-block",
                          borderRadius: "5px",
                          textDecoration: "none",
                          textAlign: "end",
                          cursor: "pointer",
                        }}
                        onClick={handleOpenModal}
                      >
                        Ajouter un Contact
                      </Link>

                    </div>
                    {isModalOpen && (
                        <AddContactModal
                          open={isModalOpen}
                          handleClose={handleCloseModal}
                          handleAddContact={handleAddContact}
                          adressEmail={emailAddress}
                        />
                      )}

                    {contactEmails.map((contact) => (
                      <div key={contact.itemId} className="contact-card ps-3 pe-3 ps-xxl-4 pe-xxl-4">
                        <div className="contact-info d-flex flex-column mb-3">
                        <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Nom</TableCell>
                                    <TableCell>Prénom</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Téléphone</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {contactEmails.map((contact) => (
                                    <TableRow key={contact.itemId}>
                                      <TableCell>{contact.name || "—"}</TableCell>
                                      <TableCell>{contact.surname || "—"}</TableCell>
                                      <TableCell>{contact.emails || "—"}</TableCell>
                                      <TableCell>{contact.phoneNumbers || "—"}</TableCell>
                                      <TableCell align="center">
                                        <IconButton
                                          color="error"
                                          onClick={() => handleDelete(contact.itemId)}
                                          aria-label="Supprimer le contact"
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                        </div>
                      </div>
                    ))}
                  </>
                ) : selectedEmail ? (
                    <div className="email-details-container">
                      <button
                        className="back-to-table-btn"
                        onClick={() => setSelectedEmail(null)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        <i className="fas fa-arrow-left" style={{ marginRight: "8px" }}></i>
                        Retour à la liste
                      </button>
                      <div className="open-email-container pb-40">
                        <div className="email-header divider d-flex justify-content-between ps-4 pe-4 ps-xxl-5 pe-xxl-5">
                          <div className="sender-info d-flex align-items-center">
                            <div className="sender-name">{selectedEmail.sender}</div>
                          </div>
                          <div className="email-info">
                            <div className="time">{selectedEmail.time}</div>
                            <button className="delete-email">
                              <img src="/assets/dashboard/images/icon/icon_29.svg" alt="icon" className="lazy-img" />
                            </button>
                          </div>
                        </div>
                        <div className="email-body divider">
                          <div className="ps-4 pe-4 ps-xxl-5 pe-xxl-5">
                            <p>{selectedEmail.body}</p>
                          </div>
                        </div>
                        <div className="email-footer">
                          <div className="ps-4 pe-4 ps-xxl-5 pe-xxl-5">
                            <div className="attachments mb-30">
                              <h6 className="m0">Attachments</h6>
                              {selectedEmail.attachments && selectedEmail.attachments.length > 0 ? (
                                selectedEmail.attachments.map((attachment, index) => (
                                  <Link
                                    key={index}
                                    to={attachment.url}
                                    className="file tran3s d-flex align-items-center mt-10"
                                    download
                                  >
                                    <div className="icon rounded-circle d-flex align-items-center justify-content-center">
                                      <img
                                        src="/assets/dashboard/images/icon/icon_31.svg"
                                        alt="icon"
                                        className="lazy-img"
                                      />
                                    </div>
                                    <div className="ps-2">
                                      <div className="file-name">{attachment.name}</div>
                                    </div>
                                  </Link>
                                ))
                              ) : (
                                <p>No Attachments</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    
                    <div className="emails-content p-3">
                      {emails.length > 0 ? (
                        <table className="email-table">
                          <thead>
                            <tr>
                              <th>
                                <input type="checkbox" onChange={handleSelectAll} />
                              </th>
                              <th>Expéditeur</th>
                              <th>Objet</th>
                              <th>Heure</th>
                            </tr>
                          </thead>
                          <tbody>
                              {emails.map((email, index) => (
                                <tr key={index} onClick={() => handleRowClick(email)}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={email.isSelected}
                                      onChange={() => handleSelectEmail(email)}
                                    />
                                  </td>
                                  <td>{email.sender}</td>
                                  <td>{email.subject}</td>
                                  <td>{new Date(email.time).toLocaleString()}</td>
                                </tr>
                              ))}
                            </tbody>
                        </table>
                      ) : (
                        <p>Aucun email trouvé.</p>
                      )}
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default DashboardMessage;
