
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAddress, setPhone, setHours, setimgMagasin } from "../../../redux/packageSlice";
import { Grid, TextField, Typography, Box, Button } from '@mui/material';
import { fetchDownloafile, generateS3PreSignedUploadUrl } from "../../../api/webPackage";
import axios from "axios";

const CandidateV3Area = ({ style_2 }) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [file, setFile]=useState();
  const [uploadError,setUploadError]=useState();
  const [errors,setErrors]=useState({});
  const [imgUrl,setImgUrl]=useState();
  const { address, phone, hours,imgMagasin } = useSelector(state => state.package || { address: '', phone: '', hours: {} });
  console.log(address, phone, hours);
  const [selectedAdresse, setSelectedAdress] = useState(address || "");
  const [selectedPhone, setSelectedPhone] = useState(phone || "");
  const [selectedHours, setSelectedHours] = useState(hours || {});
  useEffect(()=>{
    setSelectedAdress(address);
    setSelectedPhone(phone);
    setSelectedHours(hours);
  },[])

  const handleHourChange = (day, sessionIndex) => (event) => {
    const value = event.target.value;
    const [hoursInput, minutes] = value.split(':').map(Number);

    let isValid = true;
    let errorMsg = '';

    if (sessionIndex === 0 && (hoursInput < 9 || hoursInput >= 12)) {
      isValid = false;
      errorMsg = 'Début matinée: Entre 09:00 et <12:00';
    } else if (sessionIndex === 1 && (hoursInput > 12 || (hoursInput === 12 && minutes > 0))) {
      isValid = false;
      errorMsg = 'Fin matinée: ≤12:00';
    } else if (sessionIndex === 2 && (hoursInput < 14 || hoursInput >= 18)) {
      isValid = false;
      errorMsg = 'Début après-midi: Entre 14:00 et <18:00';
    } else if (sessionIndex === 3 && (hoursInput > 18 || (hoursInput === 18 && minutes > 0))) {
      isValid = false;
      errorMsg = 'Fin après-midi: ≤18:00';
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${day}-${sessionIndex}`]: isValid ? '' : errorMsg,
    }));

    if (isValid) {
      setSelectedHours(hours);
      dispatch(setHours({ day, sessionIndex, value }));
    }
  };

  

useEffect(() => {
  if (!address) dispatch(setAddress(''));
  if (!phone) dispatch(setPhone(''));
  
  if (Object.keys(hours).length === 0) {
    ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh'].forEach((day) => {
      for (let i = 0; i < 4; i++) {
        dispatch(setHours({ day, sessionIndex: i, value: '' }));
      }
    });
  }
}, [dispatch, address, phone, hours]);

const dayMap = {
  Lundi: 'first',
  Mardi: 'second',
  Mercredi: 'third',
  Jeudi: 'fourth',
  Vendredi: 'fifth',
  Samedi: 'sixth',
  Dimanche: 'seventh',
};

  const handleAddressChange = (event) => {
    // setSelectedAdress(address);
    setSelectedAdress(event.target.value);

      dispatch(setAddress(address)); 
  };

  const handlePhoneChange = (event) => {
    // setSelectedPhone(phone);
    setSelectedPhone(event.target.value);
 dispatch(setPhone(phone)); 
  };


  
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setUploadError(null); 
  };
 
  const uploadFileToS3 = async () => {
    
    try {
      const response = await generateS3PreSignedUploadUrl(projectId, file.name);
      console.log("Réponse de l'API pour l'URL pré-signée :", response);
  
      const { url, fields } = response;
      console.log("URL pré-signée :", url);
      console.log("Champs pré-signés :", fields);
  
      const fileData = new FormData();
      fileData.append("key", fields.key);
      fileData.append("policy", fields.policy);
      fileData.append("x-amz-algorithm", fields.xAmzAlgorithm);
      fileData.append("x-amz-credential", fields.xAmzCredential);
      fileData.append("x-amz-date", fields.xAmzDate);
      fileData.append("x-amz-security-token", fields.xAmzSecurityToken);
      fileData.append("x-amz-signature", fields.xAmzSignature);
      fileData.append("file", file);

      console.log("Données FormData envoyées dans la requête POST :");
      for (let pair of fileData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
              console.log("file", file.name);

      }
      console.log("Clé S3 stockée :", fields.key);
      dispatch(setimgMagasin(fields.key));
      console.log("Clé S3 stockée :", fields.key);
  
      const uploadResponse = await axios.post(url, fileData);
      console.log("Réponse du téléversement :", uploadResponse);
      dispatch(setimgMagasin(fields.key));
      console.log("Clé S3 stockée :", fields.key);
    } catch (error) {
      console.error("Erreur lors de la génération de l'URL pré-signée ou du téléversement :", error);
      if (error.response) {
        console.error("Réponse de l'API :", error.response.data);
        console.error("Statut de l'API :", error.response.status);
      } else {
        console.error("Erreur réseau ou problème de connectivité", error);
      }
    } 
  };
  console.log('rtg',imgMagasin);
  useEffect(() => {
    const getLogoUrl = async () => {
        if (imgMagasin) {
            try {
                const response = await fetchDownloafile(imgMagasin);
                setImgUrl(response.data.generateWebpackageOrderS3PreSignedDownloadUrl.url);
            } catch (error) {
                console.error("Erreur lors de la récupération de l'URL pré-signée :", error);
            }
        }
    };

    getLogoUrl();
}, [file]);
  
  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative text-center pb-80 lg-pb-60" style={{ paddingTop: "135px", paddingBottom: "30px" }}>
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                  CRÉATION PACKAGE COMMERCIAL
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to={`/package-com4N2/${projectId}`} className="btn btn-success">{"< Précédent"}</Link>
              <span className="mx-3">8/15</span>
            </div>
          </div>
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h4 className="fw-600">Remplissez les informations générales</h4>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 mb-4">
              <div className="input-group-meta position-relative mb-25">
                <label htmlFor="address">Adresse si vous avez une boutique/magasin</label>
                <TextField
                  type="text"
                  id="address"
                  name="address"
                  value={selectedAdresse}
                  onChange={handleAddressChange}
                  placeholder="Choisissez une Adresse"
                  className="form-control"
                  fullWidth
                />
              </div>
            </div>
            <div className="col-12 col-md-8 mb-4">
              <div className="input-group-meta position-relative mb-25">
                <label htmlFor="phone">Numéro de téléphone</label>
                <TextField
                  type="text"
                  id="phone"
                  name="phone"
                  value={selectedPhone}
                  onChange={handlePhoneChange}
                  placeholder="Entrez un Numéro de téléphone"
                  className="form-control"
                  fullWidth
                />
              </div>
            </div>
            <div className="col-12 col-md-8 mb-4">
              <Box sx={{ border: 1, borderRadius: '10px', borderColor: 'grey.300', p: 3 }}>
                
                <Grid container spacing={2} alignItems="center" mb={2}>
                  <Grid item xs={2}></Grid>
                  {['Début matinée', 'Fin matinée', 'Début après-midi', 'Fin après-midi'].map((session, index) => (
                    <Grid item xs={2} key={index}>
                      <Typography variant="subtitle1" align="center">
                        {session}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                
              
                {['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'].map((day) => (
                  <Grid container spacing={2} key={day} alignItems="center" mb={2}>
                    <Grid item xs={2}>
                      <Typography>{day}</Typography>
                    </Grid>
                    {['Début matinée', 'Fin matinée', 'Début après-midi', 'Fin après-midi'].map((session, sessionIndex) => {
                      const dayHours = hours[dayMap[day]] || [];
                      let value = '';
                      
                      if (sessionIndex === 0 && dayHours[0]) {
                        value = dayHours[0].split('-')[0]; 
                      } else if (sessionIndex === 1 && dayHours[0]) {
                        value = dayHours[0].split('-')[1]; 
                      } else if (sessionIndex === 2 && dayHours[1]) {
                        value = dayHours[1].split('-')[0]; 
                      } else if (sessionIndex === 3 && dayHours[1]) {
                        value = dayHours[1].split('-')[1]; 
                      }

                      return (
                        <Grid item xs={2} key={sessionIndex}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={value}
                            onChange={handleHourChange(dayMap[day], sessionIndex)}
                            placeholder="hh:mm"
                            error={!!errors[`${dayMap[day]}-${sessionIndex}`]}
                            helperText={errors[`${dayMap[day]}-${sessionIndex}`] || ''}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                ))}

              </Box>
            </div>

            <div className="col-12 col-md-8 mb-4">
              <div className="input-group-meta position-relative mb-25">
              <label htmlFor="fileUpload">Chargez vos images</label>
              <input
                type="file"
                id="fileUpload"
                className="form-control"
                multiple 
                onChange={handleFileChange}
              />
              </div>
              </div>
              <div className="row justify-content-center mt-5">
                  <div className="col-12 col-md-8 text-center">
                    <button className="btn-eleven fw-500 tran3s d-block mt-20"        
                        
                        onClick={
                            uploadFileToS3
                          }
                    >
                      Upload 
                    </button>
                  </div>
            </div>
              <div className="text-center mb-30">
                        <div className="d-flex justify-content-center mt-30">
                        {imgUrl ? (
                          <img
                           src={imgUrl}
                            alt="logo"
                            style={{ maxWidth: "200px", maxHeight: "200px",position: "center" }}
                            />
                          ) : (
                             <p>Chargement de l'image...</p>
                            )}
                        </div>
                        
                      </div>
                    
            <div className="row justify-content-center mt-5">
              <div className="col-12 col-md-8 text-center">
                <Link to={`/package-com7/${projectId}`} className="btn btn-success"        
                    
                   
                >
                  Validez
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
