import React ,{useEffect, useState} from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';
import { getWebpackageOrders } from '../../../../api/webPackage';

const IndexGed = ({setIsOpenSidebar}) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getWebpackageOrders();
        setProjects(response?.data?.getWebpackageOrders?.items || []);
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
    console.log(fetchProjects())

  }, []);
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

          <h2 className="main-title h2-title text-center">GED</h2>          

        <div className="bg-white card-box border-20">
          <div className="row justify-content-center">
             <div className="col-12">
                      { projects.map((project) =>(
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                            <Link to={`/ged/${project.projectId}`}>{project.projectName}</Link>
                        </button>
                       
                        ))}
                       
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                         <Link to="/package-com">Commander un nouveau projet WebPro</Link>
                        </button>
                        </div>
                         
          </div>
        </div>
          
      </div>      
    </div>
  );
};

export default IndexGed;