"use client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
// import CandidateFilterModal from "../common/popup/candidate-filter-modal";

// Import your icon components or use icon libraries
import { FaCalendarDay, FaCommentDots, FaQuestionCircle, FaCheckCircle } from 'react-icons/fa';

const CandidateV3Area = ({ style_2 }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
    );
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Quel est l'objet de votre question ?</h2>
                <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">Sélectionnez les thèmes que vous souhaitez aborder avec nos experts (plusieurs choix possibles)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="candidates-profile  pt-90 lg-pt-70 pb-160 xl-pb-150 lg-pb-80" style={{ backgroundColor: '#FFF' }}>
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to="/rendez-vous2">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">2/5</span>
              <Link to="/rendez-vous4">
                <button type="button" className="btn btn-success">
                  {"Suivant >"}
                </button>
              </Link>
            </div>
          </div>
          <div className="row mt-5">
            {/* Cards Section */}
            <div className="col-12">
              <div className="row">
                {/* Card 1 */}
                <div className="col-md-6 col-lg-3 mb-4">
                  <div className="card text-center">
                    <div className="card-body">
                      <input
                        type="checkbox"
                        id="option1"
                        checked={selectedOptions.includes("Des infos sur la fiscalité")}
                        onChange={() => handleCheckboxChange("Des infos sur la fiscalité")}
                      />
                      <label htmlFor="option1" className="ms-2 d-flex align-items-center">
                        <FaCalendarDay size={24} className="me-2" />
                        Des infos sur la fiscalité
                      </label>
                    </div>
                  </div>
                </div>
                {/* Card 2 */}
                <div className="col-md-6 col-lg-3 mb-4">
                  <div className="card text-center">
                    <div className="card-body">
                      <input
                        type="checkbox"
                        id="option2"
                        checked={selectedOptions.includes("Aides et financement")}
                        onChange={() => handleCheckboxChange("Aides et financement")}
                      />
                      <label htmlFor="option2" className="ms-2 d-flex align-items-center">
                        <FaCommentDots size={24} className="me-2" />
                        Aides et financement
                      </label>
                    </div>
                  </div>
                </div>
                {/* Card 3 */}
                <div className="col-md-6 col-lg-3 mb-4">
                  <div className="card text-center">
                    <div className="card-body">
                      <input
                        type="checkbox"
                        id="option3"
                        checked={selectedOptions.includes("Choix du statut juridique")}
                        onChange={() => handleCheckboxChange("Choix du statut juridique")}
                      />
                      <label htmlFor="option3" className="ms-2 d-flex align-items-center">
                        <FaQuestionCircle size={24} className="me-2" />
                        Choix du statut juridique
                      </label>
                    </div>
                  </div>
                </div>
                {/* Card 4 */}
                <div className="col-md-6 col-lg-3 mb-4">
                  <div className="card text-center">
                    <div className="card-body">
                      <input
                        type="checkbox"
                        id="option4"
                        checked={selectedOptions.includes("Autre")}
                        onChange={() => handleCheckboxChange("Autre")}
                      />
                      <label htmlFor="option4" className="ms-2 d-flex align-items-center">
                        <FaCheckCircle size={24} className="me-2" />
                        Autre
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-center mt-4">
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/rendez-vous4" className="text-white">
                  Continuer → 
                </Link>
              </button>
            </div>

          </div>
        </div>
      </section>

      {/* <CandidateFilterModal /> */}
    </>
  );
};

export default CandidateV3Area;
